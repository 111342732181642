<template>
  <div class="service-complex">
    <!-- 头部 -->
    <div class="complex-header">
      <div class="previousPage" @click="previousPage">
        <img src="@/assets/images/twoThreeImgs/return.png" alt="" />
      </div>
      <div class="ds-guanbi" @click="buttoncli">
        <img v-if="isFullscreen" src="@/assets/images/top/guanbi.png" alt="" />
        <img v-else src="@/assets/images/top/dakai.png" alt="" />
      </div>
    </div>
    <div class="helderpos">
      <!-- 左边 -->
      <div style="padding-bottom: 0.3rem;">
        <basic></basic>
        <subsidyinformation></subsidyinformation>
      </div>
      <!-- 中间 -->
      <div style="padding-bottom: 0.3rem;">
        <healthrecord></healthrecord>
      </div>
      <!-- 右边 -->
      <div style="padding-bottom: 0.3rem;">
        <orderinformation></orderinformation>
      </div>
    </div>
  </div>
</template>
<script>
import screenfull from 'screenfull'
import basic from './basic.vue'
import subsidyinformation from './subsidyinformation.vue'
import healthrecord from './healthrecord.vue'
import orderinformation from './orderinformation.vue'
export default {
  //注册组件
  components: {
    basic,
    subsidyinformation,
    healthrecord,
    orderinformation,
  },
  data() {
    return {
      //全屏
      isFullscreen: false,
    }
  },
  methods: {
    // 全屏
    buttoncli() {
      //   判断浏览器是否支持全屏
      if (!screenfull.isEnabled) {
        this.$message({
          message: '你的浏览器不支持全屏',
          type: 'warning',
        })
        return false
      }
      // 将样式改为锁屏
      if (this.isFullscreen == false) {
        //   打开全屏
        this.isFullscreen = true
        screenfull.toggle()
      } else {
        this.isFullscreen = false
        screenfull.toggle()
      }
    },
    previousPage() {
      this.$router.back()
    },
  },
}
</script>
<style scoped>
.service-complex {
  background: url('~@/assets/images/zhangzhe/background.png') no-repeat 0 0 /
    contain;
  background-size: 100% 100%;
  height: 45rem;
}
.complex-header {
  background: url('~@/assets/images/zhangzhe/top_img.png') 0 0 no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1920/76;
  position: relative;
}
.complex-header > img {
  height: 2rem;
  width: 60rem;
}

.previousPage {
  position: absolute;
  left: 1rem;
  top: 0.7rem;
}
.ds-guanbi {
  position: absolute;
  right: 1rem;
  top: 0.7rem;
}
/* 按钮的颜色 */
.previousPage .el-button:hover,
.el-button:focus {
  color: #ffffff;
  border-color: #a6bafd;
  background-color: #031e6b;
}
.previousPage .el-button {
  color: #ffffff;
  border-color: #a6bafd;
  background-color: #031e6b;
}
.previousPage .el-button--primary {
  background: #031e6b;
  border-color: #a6bafd;
  color: #ffffff;
  padding: 0.3rem 0.3rem;
}
.previousPage .el-button--primary:hover,
.el-button--primary:focus {
  background: #031e6b;
  border-color: #a6bafd;
  color: #ffffff;
}
.previousPage .el-button--primary:hover,
.el-button--primary:focus {
  background: #031e6b;
  border-color: #a6bafd;
  color: #ffffff;
}
.helderpos {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
}
</style>
