<template>
  <div class="ds-basic-cen">
    <div class="ds-page">
      <div class="ds-female">
        <img src="@/assets/images/zhangzhe/female.png" alt="" />
        <div style="margin-left:0.42rem">
          <div class="ds-name">
            <span>{{ name }}</span>
            <span v-if="intercare">{{ intercare }}</span>
          </div>
          <div class="ds-detail">
            <span>{{ died ? '去世' : '健在' }}</span>
            <span style="margin-left: 0.3rem;">{{
              gender == 1 ? '男' : '女'
            }}</span>
            <span style="margin-left: 0.3rem;">{{ age }}岁</span>
          </div>
        </div>
      </div>
      <div class="ds-creen" v-for="(item, index) in list" :key="index">
        <span class="ds-bulid">{{ item.i }}</span>
        <span :class="index == 7 ? 'ds-tits' : 'ds-tit'">{{ item.t }}</span>
      </div>
      <div class="ds-creen">
        <span class="ds-bulid">关系信息：</span>
        <el-table
          :data="datalist"
          :cell-style="{ borderColor: '#213060' }"
          height="150"
          style="width: 100%;margin-top: 0.21rem;overflow-y: auto;background:rgba(5,13,38,0.31);"
          :header-cell-style="{ background: '#152452' }"
        >
          <el-table-column
            width="120"
            show-overflow-tooltip
            prop="name"
            label="姓名"
          >
          </el-table-column>
          <el-table-column width="50" prop="relationshipMark" label="关系">
          </el-table-column>
          <el-table-column width="120" prop="phone" label="电话">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { getAreaCoter, getAreaassessment } from '@/api/elderlyRecords/routineCheck.js'
export default {
  data() {
    return {
      list: [
        { i: '身份证：', t: '' },
        { i: '手机号：', t: '' },
        { i: '生日：', t: '' },
        { i: '出生日期：', t: '' },
        { i: '户口性质：', t: '' },
        { i: '户籍地址：', t: '' },
        { i: '籍贯：', t: '' },
        { i: '地址信息：', t: '' },
      ],
      datalist: [],
      customerGlobalId: '', //老人id
      name: '',
      gender: '',
      age: '',
      died: '',
      intercare: '',
    }
  },
  created() {
    this.customerGlobalId = this.$route.query.customerGlobalId
  },
  mounted() {
    this.getAreaCoter()
    this.getAreaassessment()
  },
  methods: {
    getAreaassessment() {
      getAreaassessment(this.customerGlobalId).then((res) => {
        if (res.code == 0) {
          this.intercare = res.data
        }
      })
    },
    getAreaCoter() {
      getAreaCoter(this.customerGlobalId).then((res) => {
        if (res.code == 0) {
          this.name = res.data.name
          this.gender = res.data.gender
          this.age = res.data.age
          this.died = res.data.died
          this.list[0].t = res.data.idCard
          this.list[1].t = res.data.phone
          let bi = res.data.birthday.substring(5, 7)
          let by = res.data.birthday.substring(8, 10)
          bi = bi >= 10 ? bi + '月' : bi.slice(1) + '月'
          by = by >= 10 ? by + '日' : by.slice(1) + '日'
          this.list[2].t = bi + by
          this.list[3].t = res.data.birthday
          this.list[4].t =
            res.data.census == 'AGRICULTURE' ? '农业' : '非农'
          this.list[5].t = res.data.registeredAddress
          this.list[6].t = res.data.ancestralResidenceAddress
          this.list[7].t = res.data.addresses[0].append
            ? res.data.addresses[0].append
            : ''
          this.datalist = res.data.contacts
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
::v-deep .el-table thead {
    color: #5176A0;
    font-weight: 500;
}
::v-deep .el-table {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    color: #8EB8E6;
}
.ds-basic-cen {
  background: url('~@/assets/images/zhangzhe/bg1.png') 0 0 no-repeat;
  background-size: 100% 100%;
  width: 17.08rem;
  height: 22.75rem;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  margin-top: 0.41rem;
}

.ds-page {
  position: absolute;
  top: 1.8rem;
  left: 0.42rem;
  right: 0.42rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  padding-right: 0.416rem;
}
.ds-female {
  display: flex;
  align-items: center;
  margin-top: 0.58rem;
}
.ds-female > img {
  width: 3.33rem;
  height: 3.33rem;
  margin-left: 1rem;
}
.ds-name {
  display: flex;
  align-items: center;
  > span:nth-of-type(1) {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }
  > span:nth-of-type(2) {
    background: #161f42;
    border-radius: 2px;
    font-size: 0.5rem;
    font-weight: 400;
    color: #ff8529;
    padding: 0.1rem 0.3rem;
    margin-left: 0.3rem;
  }
}
.ds-detail {
  margin-top: 0.3rem;
}
.ds-detail > span {
  background: #161f42;
  border-radius: 2px;
  font-size: 0.5rem;
  font-weight: bold;
  color: #25d64d;
  padding: 0.1rem 0.3rem;
}
.ds-creen {
  display: flex;
  margin-top: 0.42rem;
}
.ds-bulid {
  width: 3.5rem;
  font-size: 0.6rem;
  font-weight: 400;
  color: #b4c6fe;
  text-align: right;
  opacity: 0.6;
}
::v-deep .el-table__empty-text {
    line-height: 60px;
    width: 50%;
    color: #2d3769;
}
.ds-tit {
  font-size: 0.5833rem;
  font-weight: 400;
  color: #8eb8e6;
}
.ds-lar {
  width: 10rem;
  background: #152452;
  display: flex;
  font-size: 0.6rem;
  font-weight: bold;
  color: #5176a0;
  padding: 0.42rem 0.83rem;
  justify-content: space-between;
}
.ds-comp {
  width: 10rem;
  display: flex;
  font-size: 0.6rem;
  font-weight: bold;
  color: #5176a0;
  padding: 0.42rem 0.83rem;
  justify-content: space-between;
  border-bottom: 1px solid #1c3269;
}
.ds-fdsfsdf {
  height: 5rem;
  overflow-y: auto;
}
::-webkit-scrollbar {
  display: none;
}
.ds-tits {
  width: 12rem;
  font-size: 0.5833rem;
  font-weight: 400;
  color: #8eb8e6;
  white-space: nowrap;
  /*超出的空白区域不换行*/
  overflow: hidden;
  /*超出隐藏*/
  text-overflow: ellipsis;
}
::v-deep .el-table--border::after, .el-table--group::after, .el-table::before {
    content: '';
    position: absolute;
    background-color: #1C3269;
    z-index: 1;
}
::v-deep .el-table__row {
    background-color: #050E33;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
::v-deep .el-table__body tr:hover > td {
  background-color: rgba(255, 133, 41, 0.2) !important;
}
</style>
