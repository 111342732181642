<template>
  <div class="ds-basic-i">
    <div class="ds-center1">
      <div class="ds-center-box" style="padding-top: 0.5rem;">
        <div class="ds-page-seer">
          <div
            :class="t_tit == index ? 'ds-check-itme1' : 'ds-check-itme2'"
            v-for="(item, index) in strList"
            :key="index"
            @click="btn_box(index, item.value)"
          >
            {{ item.name }}
          </div>
        </div>
        <!-- 常规检查项 -->
        <div v-if="t_tit == 0">
          <routineCheck :customerGlobalId="customerGlobalId"></routineCheck>
        </div>
        <!-- 血压 -->
        <div v-if="t_tit == 1">
          <bloodPressure :customerGlobalId="customerGlobalId"></bloodPressure>
        </div>
        <!-- 血糖 -->
        <div v-if="t_tit == 2">
          <bloodGlucose :customerGlobalId="customerGlobalId"></bloodGlucose>
        </div>
        <!-- 血脂 -->
        <div v-if="t_tit == 3">
          <bloodFat :customerGlobalId="customerGlobalId"></bloodFat>
        </div>
        <!-- 血常规 -->
        <div v-if="t_tit == 4">
          <bloodRoutine :customerGlobalId="customerGlobalId"></bloodRoutine>
        </div>
        <!-- 尿酸 -->
        <div v-if="t_tit == 5">
          <uricAcid :customerGlobalId="customerGlobalId"></uricAcid>
        </div>
        <!-- <div
          id="heartRate"
          style="height: 13rem; width: 100%;margin-top: 0.33rem;"
        ></div> -->
      </div>
      <div
        class="ds-center-box1"
        style="padding-top: 0.3rem;margin-top: 0.2rem;"
      >
        <span class="ds-cen-jws">既往史</span>
        <div style="display: flex;align-items: center;flex-wrap: wrap;">
          <!-- 慢性病 -->
          <div style="width: 48%;">
            <p class="ds-cen-mxb" style="opacity:0.6">慢性病：</p>
            <el-table
              :data="tableData1"
              :cell-style="{ borderColor: '#213060' }"
              style="width: 100%;margin-top: 0.15rem;height: 3.5rem;"
              :header-cell-style="{ background: '#152452' }"
            >
              <el-table-column
                width="120"
                show-overflow-tooltip
                prop="itemValue"
                label="类型"
              >
              </el-table-column>
              <el-table-column
                width="140"
                show-overflow-tooltip
                prop="remark"
                label="名称"
              >
              </el-table-column>
              <el-table-column width="180" prop="when" label="确诊时间">
              </el-table-column>
            </el-table>
            <div class="ds-cen-ckgd" @click="btn_Seemore(1)">
              {{ tableData1.length ? '查看更多' : '' }}
            </div>
          </div>
          <!-- 既往病史： -->
          <div style="width: 48%;margin-left: 0.3rem;">
            <p class="ds-cen-mxb" style="opacity:0.6">既往病史：</p>
            <el-table
              :data="tableData2"
              :cell-style="{ borderColor: '#213060' }"
              style="width: 100%;margin-top: 0.15rem;height: 3.5rem;"
              :header-cell-style="{ background: '#152452' }"
            >
              <el-table-column
                width="120"
                show-overflow-tooltip
                prop="itemValue"
                label="类型"
              >
              </el-table-column>
              <el-table-column
                width="140"
                show-overflow-tooltip
                prop="remark"
                label="名称"
              >
              </el-table-column>
              <el-table-column width="180" prop="when" label="确诊时间">
              </el-table-column>
            </el-table>
            <div class="ds-cen-ckgd" @click="btn_Seemore(2)">
              {{ tableData2.length ? '查看更多' : '' }}
            </div>
          </div>
          <!-- 家族史 -->
          <div style="width: 48%;margin-top: 0.2rem;">
            <p class="ds-cen-mxb">家族史</p>
            <el-table
              :data="tableData3"
              :cell-style="{ borderColor: '#213060' }"
              style="width: 100%;margin-top: 0.15rem;height: 3.5rem;"
              :header-cell-style="{ background: '#152452' }"
            >
              <el-table-column width="170" prop="itemValue" label="关系">
              </el-table-column>
              <el-table-column
                width="210"
                show-overflow-tooltip
                prop="remark"
                label="名称"
              >
              </el-table-column>
            </el-table>
            <div class="ds-cen-ckgd" @click="btn_Seemore(3)">
              {{ tableData3.length ? '查看更多' : '' }}
            </div>
          </div>
          <!-- 过敏信息 -->
          <div style="width: 48%;margin-left: 0.3rem; margin-top: 0.2rem;">
            <p class="ds-cen-mxb">过敏信息</p>
            <el-table
              :data="tableData4"
              :cell-style="{ borderColor: '#213060' }"
              style="width: 100%;margin-top: 0.15rem;height: 3.5rem;"
              :header-cell-style="{ background: '#152452' }"
            >
              <el-table-column
                width="170"
                show-overflow-tooltip
                prop="itemValue"
                label="过敏原类型"
              >
              </el-table-column>
              <el-table-column
                width="210"
                show-overflow-tooltip
                prop="remark"
                label="过敏原"
              >
              </el-table-column>
            </el-table>
            <div class="ds-cen-ckgd" @click="btn_Seemore(4)">
              {{ tableData4.length ? '查看更多' : '' }}
            </div>
          </div>
        </div>
        <span class="ds-cen-xinxi">残疾信息</span>
        <div class="ds-cen-canji">
          <span>残疾证编号：{{ canji_no }}</span>
          <span v-if="level">{{ level }} {{ canji_type }}</span>
        </div>
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
      <el-table
        :data="tableData5"
        :cell-style="{ borderColor: '#213060' }"
        height="350"
        style="width: 100%;margin-top: 0.21rem;overflow-y: auto;background: #040929;"
        :header-cell-style="{ background: '#152452' }"
      >
        <el-table-column show-overflow-tooltip prop="itemValue" :label="mubn">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="remark" label="名称">
        </el-table-column>
        <el-table-column prop="when" :label="mubn == '类型' ? '确诊时间' : ''">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import timeQuery from './timeQuery.vue'
import warningImage from '@/assets/images/zhangzhe/warning.png'
import routineCheck from './components/routineCheck.vue'
import bloodPressure from './components/bloodPressure.vue'
import bloodGlucose from './components/bloodGlucose.vue'
import bloodFat from './components/bloodFat.vue'
import bloodRoutine from './components/bloodRoutine.vue'
import uricAcid from './components/uricAcid.vue'
import Swiper from 'swiper'
let echarts = require('echarts')
import { getAreachives, getArehistory } from '@/api/elderlyRecords/routineCheck.js'
export default {
  data() {
    return {
      dialogVisible: false,
      title: '',
      strList: [
        { name: '常规检查项', value: 'bodyMassIndex' },
        { name: '血压', value: 'bloodPressure' },
        { name: '血糖', value: 'bloodSugar' },
        { name: '血脂', value: 'bloodFat' },
        { name: '血常规', value: 'bloodTesting' },
        { name: '尿酸', value: 'uricAcid' },
      ],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      tableData5: [],
      t_tit: 0,
      t_time: 3,
      value1: '',
      customerGlobalId: '', //老人id
      mubn: '',
      level: '',
      canji_no: '',
      canji_type: '',
      sty: {},
    }
  },
  components: {
    Swiper,
    timeQuery,
    routineCheck,
    bloodPressure,
    bloodGlucose,
    bloodFat,
    bloodRoutine,
    uricAcid,
  },

  created() {
    this.customerGlobalId = this.$route.query.customerGlobalId
  },
  mounted() {
    // this.heartRate()
    this.getAreachives()
  },
  methods: {
    useSwiper() {
      new Swiper('.swiper-container', {
        //direction: 'vertical', // 垂直切换选项
        //mousewheel: true, //滚轮
        autoplay: false,
        loop: false, // 循环模式选项
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
        spaceBetween: 10,
        on: {
          click: function(e) {},
        },
      })
    },

    getArehistory(data) {
      getArehistory(data).then((res) => {})
    },
    getAreachives() {
      getAreachives(this.customerGlobalId).then((res) => {
        if (res.code == 0 && res.data != null) {
          this.tableData1 = res.data.detail.diseaseHistory
          this.tableData2 = res.data.detail.pastHistory
          this.tableData2.forEach((item) => {
            item.itemValue = item.type
          })
          this.tableData3 = res.data.detail.geneticDiseaseHistory
          this.tableData3.forEach((item) => {
            item.itemValue = item.relationValue
          })
          this.tableData4 = res.data.detail.allergens
          this.level = res.data.detail.handicapped.level
          this.canji_no = res.data.detail.handicapped.no
          this.canji_type = res.data.detail.handicapped.type
        }
      })
    },
    btn_Seemore(e) {
      this.mubn = e == 3 ? '关系' : e == 4 ? '过敏原类型' : '类型'
      this.title =
        e == 1 ? '慢性病' : e == 2 ? '既往病史' : e == 3 ? '家族史' : '过敏信息'
      this.tableData5 =
        e == 1
          ? this.tableData1
          : e == 2
          ? this.tableData2
          : e == 3
          ? this.tableData3
          : this.tableData4
      console.log(this.tableData5)
      this.dialogVisible = true
    },
    btn_box(e, i) {
      this.t_tit = e
      this.sty.type = i
      //   this.getArehistory(this.sty)
    },
    heartRate() {
      let dom = document.getElementById('heartRate')
      let myChart = echarts.init(dom)
      let option = {
        //dataZoom-inside 内置型数据区域缩放组件 所谓内置 1平移：在坐标系中滑动拖拽进行数据区域平移。2缩放：PC端：鼠标在坐标系范围内滚轮滚动（MAC触控板类同;移动端：在移动端触屏上，支持两指滑动缩放。
        dataZoom: [
          {
            type: 'inside', //1平移 缩放
            throttle: '50', //设置触发视图刷新的频率。单位为毫秒（ms）。
            minValueSpan: 6, //用于限制窗口大小的最小值,在类目轴上可以设置为 5 表示 5 个类目
            start: 1, //数据窗口范围的起始百分比 范围是：0 ~ 100。表示 0% ~ 100%。
            end: 50, //数据窗口范围的结束百分比。范围是：0 ~ 100。
            zoomLock: true, //如果设置为 true 则锁定选择区域的大小，也就是说，只能平移，不能缩放。
          },
        ],
        // 主要用来控制图表四周留白

        tooltip: {
          trigger: 'axis',
          //   showContent: false, //关闭原有样式
          enterable: true,
          backgroundColor: 'transparent', //设置背景图片 rgba格式
          axisPointer: {
            lineStyle: {
              width: 2,
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255, 133, 41, 0)', // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255, 133, 41, 1)', // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255, 199, 41, 0)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
          //   position: 'top',
          formatter: function(params) {
            //return回调一个模板字符串，自定义提示框的形状
            return `
             <div class="chartTooltipBox">
                <div class='chartTooltip'>
                   <p class='serviceDate'>${1111}</p>
                   <p class='serviceCount'>服务次数：
                    <span style='color:#FF8529'>${222}</span>
                   </p>
                   <p class='serviceName'>服务项目: <br />
                     ${333}
                    </p>
                </div>
             </div>
              `
          },
        },
        grid: {
          //调整地图位置
          left: '3%',
          right: '4%',
          bottom: '0%',
          top: '3%',
          containLabel: true,
        },
        xAxis: {
          //横坐标样式
          axisTick: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: '#8EB8E6',
              fontSize: 12,
            },
          },
          data: [1, 2, 3, 4, 5, 6],
          axisLine: {
            lineStyle: {
              color: '#394788',
            },
          },
        },
        yAxis: [
          {
            type: 'value',
            name: '分/次',
            nameTextStyle: {
              color: 'red',
            },
            axisTick: {
              show: false,
            },
            //纵坐标样式
            splitLine: {
              show: true,
              lineStyle: { type: 'dashed', color: '#394788' },
            },
            axisLabel: {
              textStyle: {
                color: '#8EB8E6',
                fontSize: 14,
              },
            },
            axisLine: {
              lineStyle: {
                color: '#394788',
              },
            },
          },
        ],
        graphic: [
          //地图的背景  这里放的是图片，也可以是纯颜色背景
          {
            type: 'image',
            style: {
              backgroundColor: '#ccc',
              x: 70,
              y: 35,
              width: 680,
              height: 220,
            },
          },
        ],
        series: [
          {
            name: '数量',
            type: 'line',
            areaStyle: {
              //折线区域颜色
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //设置折线渐变色
                { offset: 0, color: 'rgba(255,123,58,.4)' },
                { offset: 0.3, color: 'rgba(255,123,58,.3)' },
                { offset: 0.5, color: 'rgba(255,123,58,.2)' },
                { offset: 1, color: 'rgba(255,123,58,.1)' },
              ]),
            },
            showSymbol: true,
            symbol: 'circle', //拐点的形状
            itemStyle: {
              normal: {
                lineStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    //设置折线渐变色
                    { offset: 0, color: '#FF7B3A' },
                    { offset: 1, color: '#FF7B3A' },
                  ]),
                },
                color: '#FF7B3A', //这里设置的拐点颜色
                borderColor: '#FF7B3A', //  拐点边框颜色
                borderWidth: 6, //  拐点边框宽度
              },
            },
            label: {
              show: true,
              distance: 3,
              backgroundColor: 'rgba(255,213,213,0.65)',
              borderColor: '#FF7A7A', //外层边框颜色
              borderWidth: 0.5, //外层边框宽度
              borderRadius: 2, //外层边框圆角
              formatter: (params) => {
                console.log(params)
                if (params) {
                  if (
                    1
                    // params.data < this.lineData[0].min ||
                    // params.data > this.lineData[0].max
                  ) {
                    return '{a|}{img|}{leftGap|}{value|' + 65 + '}'
                  } else {
                    return ''
                  }
                } else {
                  return ''
                }
              },
              rich: {
                a: {
                  height: 24,
                  width: 2,
                  padding: [0, 8, 0, 0],
                },
                leftGap: { height: 24, width: 2 },
                img: {
                  backgroundColor: {
                    image: warningImage,
                  },
                  align: 'right',
                  height: 13,
                },
                value: {
                  height: 20,
                  fontSize: 12,
                  color: '#FF4D4F',
                  padding: [2, 8, 0, 0],
                },
                num: {
                  borderWidth: 0,
                  borderRadius: 0,
                  color: '#595959',
                },
              },
            },
            emphasis: {},
            data: [2, 3, 4, 5, 6, 1, 4],
          },
        ],
      }
      myChart.setOption(option)
    },
  },
}
</script>
<style scoped lang="scss">
::v-deep .el-table thead {
  color: #5176a0;
  font-weight: 500;
}
::v-deep .el-table {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  color: #8eb8e6;
}
::-webkit-scrollbar {
  display: none;
}
.ds-basic-i {
  background: url('~@/assets/images/zhangzhe/bj5.png') 0 0 no-repeat;
  background-size: 100% 100%;
  width: 34.41rem;
  height: 40.75rem;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 0.41rem;
  position: relative;
}
.ds-center1 {
  position: absolute;
  top: 1.66rem;
  left: 0.42rem;
  right: 0.42rem;
}
.ds-center-box {
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  padding-left: 0.67rem;
  height: 19.8rem;
  padding-bottom: 0.5rem;
}
.ds-center-box1 {
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  padding-left: 0.67rem;
  height: 17.29rem;
}
.ds-page-seer {
  display: flex;
  align-items: center;
  margin-left: -1.3rem;
}
.ds-check-itme1 {
  display: flex;
  font-size: 0.58rem;
  font-weight: bold;
  color: #ff8529;
  margin-left: 1.3rem;
  position: relative;
  &::after {
    content: '';
    width: 100%;
    height: 2px;
    background: #ff8529;
    position: absolute;
    bottom: -0.2rem;
  }
}
.ds-check-itme2 {
  display: flex;
  font-size: 0.58rem;
  font-weight: 400;
  color: #8eb8e6;
  margin-left: 1.3rem;
}
.ds-data-time {
  display: flex;
  height: 1.3rem;
  margin-top: 0.67rem;
}
.ds-time1 {
  font-size: 0.59rem;
  font-weight: 400;
  color: #ffffff;
  padding: 0.2rem 0.8rem;
  background: #36447d;
  border: 1px solid #5869ab;
}
.ds-time2 {
  font-size: 0.59rem;
  font-weight: 400;
  color: #8eb8e6;
  padding: 0.2rem 0.8rem;
  background: #18214a;
  border: 1px solid #26305a;
}
.ds-data-picker {
  display: flex;
  margin-left: 1rem;
  align-items: center;
}
.ds-data-picker > span {
  font-size: 0.59rem;
  font-weight: 400;
  color: #8eb8e6;
}
.ds-data-picker .el-input__inner {
  z-index: 99999999;
  background: #232d57 !important;
  font-size: 0.59rem;
  font-weight: 400;
  color: #8eb8e6;
}
.ds-xinlu-ts {
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
  > span:nth-of-type(1) {
    font-size: 0.66rem;
    font-weight: bold;
    color: #b4c6fe;
  }
  > span:nth-of-type(2) {
    font-size: 0.5833rem;
    font-weight: 400;
    color: #8eb8e6;
    margin-left: 0.3rem;
  }
}
.ds-serve-num {
  font-size: 0.5833rem;
  font-weight: 400;
  color: #ff8529;
  display: block;
  margin-top: 0.3rem;
}
.ds-cen-jws {
  font-size: 0.66rem;
  font-weight: bold;
  color: #b4c6fe;
}
.ds-cen-mxb {
  font-size: 0.5833rem;
  font-weight: 400;
  color: #b4c6fe;
  margin-top: 0.3rem;
}
::v-deep .el-table tr {
  z-index: 999999999;
  background: #060f35 !important;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
::v-deep .el-table::before {
  background: none !important;
}
.ds-cen-ckgd {
  font-size: 0.5833rem;
  font-weight: 400;
  color: #ff8529;
  text-align: center;
  padding: 0.46rem 0.46rem;
  border-bottom: 1px solid #1c3269;
}
.ds-cen-xinxi {
  display: block;
  font-size: 0.66rem;
  font-weight: bold;
  color: #b4c6fe;
  margin-top: 0.53rem;
}
.ds-cen-canji {
  font-size: 0.5833rem;
  font-weight: 400;
  color: #8eb8e6;
  margin-top: 0.3rem;
  > span:nth-of-type(1) {
  }
  > span:nth-of-type(2) {
    margin-left: 4.83rem;
  }
}
.ds-cen-slide {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ds-cen-mingchen {
  display: flex;
  align-items: center;
  margin-right: 0.416rem;
}
.ds-cen-mingchen > span {
  padding: 0.43rem;
  background: #18214a;
  font-size: 0.58rem;
  font-weight: 400;
  color: #b4c6fe;
}
.under-right-cs {
  position: relative;
  width: 20%;
  height: 100%;
}
.under-right-cs {
  display: flex;
  justify-content: center;
}
.el-tabs__header {
  margin: 0 !important;
}
::v-deep .el-tabs__item.is-active {
  color: #b4c6fe;
  font-size: 0.58rem;
  font-weight: 400;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
  background: linear-gradient(
    92deg,
    #18214a 0%,
    #18214a 48.8525390625%,
    #18214a 100%
  );
  /* width: 9%; */
}
::v-deep .el-radio-button__inner {
  color: #8eb8e6;
}
::v-deep [data-v-329fb8b1] .el-input__inner {
  -webkit-appearance: none;
  /* background-color: #FFF; */
  background-image: none;
  border-radius: 4px;
  /* border: 1px solid #DCDFE6; */
  box-sizing: border-box;
  color: #8eb8e6;
  display: inline-block;
  font-size: inherit;
  height: 1.33rem;
  line-height: 1.33rem;
  outline: 0;
  padding: 0 40px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  background: #18214a;
  border: 1px solid #232d57;
}
::v-deep .el-input--small .el-input__icon {
  line-height: 33px;
}
::v-deep .el-table__empty-text {
  line-height: 60px;
  width: 50%;
  color: #2d3769;
}
.el-tabs__item,
.is-top {
  color: #b4c6fe;
  font-size: 0.58rem;
  font-weight: 400;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  background: #18214a;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-bottom: none;
  border: none !important;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border-bottom: none !important;
  border-left: none !important;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ds-cen-info {
  display: flex;
  background: #18214a;
  border-radius: 2px;
  padding: 0.2rem;
  font-size: 0.583;
  font-weight: 400;
  color: #b4c6fe;
  margin-right: 0.416rem;
}
.ds-cen-info1 {
  display: flex;
  background: #18214a;
  border-radius: 2px;
  padding: 0.2rem;
  font-size: 0.583;
  font-weight: 400;
  color: #b4c6fe;
  width: 50px;
  overflow: hidden;
}
::v-deep .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  border-radius: 2px;
  box-sizing: border-box;
  width: 50%;
  background: rgba(4, 9, 41, 0.95);
  box-shadow: inset 0px 0px 24px 0px rgba(0, 122, 255, 0.81);
  border-radius: 2px;
  border: 1px solid #0c87ff;
}
::v-deep .el-dialog__body {
  padding: 30px 20px;
  color: #606266;
  font-size: 0.5833rem;
  word-break: break-all;
  border-top: 1px solid #242b5f;
}
::v-deep .el-dialog__title {
  color: #ffffff;
}
::v-deep .el-table__body tr:hover > td {
  background-color: rgba(255, 133, 41, 0.2) !important;
}
.ds-cen-fund {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.33rem;
  > span:nth-of-type(1) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #8eb8e6;
  }
}
.ds-cen-miss {
  display: flex;
  align-items: center;
}
.ds-cen-miss > div {
  width: 1.08rem;
  height: 0.33rem;
  background: #ff8529;
  border-radius: 7px;
  margin-right: 0.3rem;
}
.ds-cen-miss > span {
  font-size: 0.5833rem;
  font-weight: 400;
  color: #ff8529;
  margin-right: 0.416rem;
}
.el-table__empty-block {
  background: #060f35 !important;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.img_swiper {
  width: 100px;
  height: 30px;
  background: #18214a;
  margin-right: 0.4166rem;
  line-height: 30px;
}
.swiper-button-prev,
.swiper-button-next {
  margin-top: -12px !important;
}
.swiper-button-next {
  position: absolute;
  right: -12px !important;
}
.swiper-wrapper {
  text-align: center !important;
  color: #b4c6fe !important;
}
::v-deep .el-table,
.el-table__expanded-cell {
  background-color: #081036;
}
::v-deep .el-picker-panel {
  color: #606266;
  border: 1px solid #e4e7ed;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background: red;
  border-radius: 4px;
  line-height: 30px;
  margin: 5px 0;
}
</style>
<style lang="scss"></style>
