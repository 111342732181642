<template>
  <!-- 血糖 -->
  <div class="all-records">
    <!-- 查询 -->
    <time-query @changeTime="changeTime"></time-query>
    <!-- echarts图表 -->
    <div class="line-chart-box" style="margin-top: 10px;">
      <div class="emptyMessage" v-if="isData.length == 0">
        暂无数据
      </div>
      <LineChart
        :showTimes="showTimes"
        :name="'血糖'"
        :unit="'mmol/L'"
        :lineCartId="'bloodGlucose'"
        :lineData="bloodGlucose"
        :abscissaData="abscissaData"
        v-if="abscissaData.length != 0 && flag"
      ></LineChart>
    </div>
  </div>
</template>

<script>
// api请求
import { getArehistory, getAreaCoter } from '@/api/elderlyRecords/routineCheck.js'
import timeQuery from '../timeQuery.vue'
import LineChart from './compon/bloodGlucose.vue'
export default {
  props: {
    // 传过来的身份证号
    customerGlobalId: {
      type: [String],
      default: '',
    },
    gender: {
      type: [String, Number],
    },
  },
  components: { timeQuery, LineChart },
  // 定义数据
  data() {
    return {
      // 查询表单数据
      formInline: {
        date: '',
      },
      showTimes: '',
      flag: true,
      abscissaData: [],
      bloodGlucose: [
        {
          name: '空腹',
          range: 'mmol/L(正常范围：3.9mmol/L-6.1mmol/L)',
          max: 6.1,
          min: 3.9,
          color: '#FF8529',
          average: '',
          data: [],
        },
        {
          name: '餐后两小时',
          range: 'mmol/L(正常范围：4.4mmol/L-7.8mmol/L)',
          max: 7.8,
          min: 4.4,
          color: '#0587AF',
          average: '',
          data: [],
        },
        {
          name: '随机',
          range: 'mmol/L',
          //   max: 7.8,
          //   min: 4.4,
          color: '#3C9C0D',
          average: '',
          data: [],
        },
      ],
      isData: [], // 是否有数据
    }
  },
  // dom加载
  mounted() {},
  // 定义函数
  methods: {
    changeTime(data) {
      const { date, showTimes, start, end } = data
      this.showTimes = showTimes
      this.formInline.date = date
      this.init(start, end)
    },
    async init(start, end) {
      this.flag = false
      const res = await getArehistory({
        customerGlobalId: this.customerGlobalId,
        date: this.formInline.date,
        type: 'bloodSugar',
        start,
        end,
      })
      console.log(res)
      if (res.code == 0) {
        //处理数据
        this.flag = false
        this.isData = res.data.collectionTime
        //横坐标
        this.abscissaData = res.data.collectionTime
        //空腹血糖
        const fastingBloodGlucoseAverage = _.mean(
          _.cloneDeep(
            res.data.fastingBloodGlucose.filter((item) => {
              return item
            })
          )
        )
        // 餐后两小时血糖
        const postprandialBloodGlucoseAverage = _.mean(
          _.cloneDeep(
            res.data.postprandialBloodGlucose.filter((item) => {
              return item
            })
          )
        )
        //随机血糖
        const randomBloodGlucoseAverage = _.mean(
          _.cloneDeep(
            res.data.randomBloodGlucose.filter((item) => {
              return item
            })
          )
        )
        this.bloodGlucose[0].average = fastingBloodGlucoseAverage
        this.bloodGlucose[1].average = postprandialBloodGlucoseAverage
        this.bloodGlucose[2].average = randomBloodGlucoseAverage
        this.bloodGlucose[0].data = res.data.fastingBloodGlucose
        this.bloodGlucose[1].data = res.data.postprandialBloodGlucose
        this.bloodGlucose[2].data = res.data.randomBloodGlucose
        //收缩压
        this.flag = true

        console.log(this.bloodGlucose, this.abscissaData, 333333)
      }
    },
    processingData(abscissaData, ordinateData) {
      ordinateData.forEach((item, index) => {
        if (item == null) {
          abscissaData[index] = null
        }
      })
      ordinateData = ordinateData.filter(function(i) {
        return i
      })
      abscissaData = abscissaData.filter(function(i) {
        return i
      })
      let mean
      if (ordinateData !== null && ordinateData.length != 0) {
        mean = _.mean(ordinateData)
      }
      return {
        abscissaData: abscissaData,
        ordinateData: ordinateData,
        mean: mean,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.all-records {
  position: relative;
}
.emptyMessage {
  position: absolute;
  font-size: 1.2rem;
  color: #2d3769;
  top: 9rem;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
