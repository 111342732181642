<template>
  <div>
    <div class="ds-basic-is" v-if="institutionNo_type == 3">
      <div class="ds-page3">
        <div class="ds-page-pk">
          <span class="ds-htx-x">合同信息</span>
          <div class="ds-pate">
            所属合同：
            <el-select
              v-model="value1"
              @change="contractChange"
              placeholder="请选择"
              popper-class="ds-cen-popper-class"
            >
              <el-option
                v-for="item in shipContract"
                :key="item.id"
                :label="item.contractName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="ds-pate"
            style="padding-bottom: 0.75rem; margin-top: 1rem"
          >
            合同类型：
            <el-select
              v-model="value2"
              @change="contractValue"
              placeholder="请选择"
              popper-class="ds-cen-popper-class"
            >
              <el-option
                v-for="item in contractBusiness"
                :key="item.contractBusiness"
                :label="item.contractBusinessMark"
                :value="item.contractBusiness"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="ds-page-pkou" style="margin-top: 0.3rem">
          <div class="ds-buzhu">
            <span>补助信息</span>
            <span @click="btn_delits">查看详情</span>
          </div>
          <div class="ds-chares">
            <div class="ds-temp">
              <div
                class="ds-side"
                v-for="(item, index) in modlist"
                :key="index"
              >
                <span class="ds-mony">{{ item.t }}</span>
                <div class="ds-ration">
                  <span class="ds-ment">{{ item.i }}</span>

                  <el-popover
                    v-if="
                      index == 0 &&
                      value2 == 'HOME_SERVICE' &&
                      giveLog[0].giverName
                    "
                    placement="right"
                    width="400"
                    trigger="click"
                    popper-class="pophover"
                  >
                    <div
                      class="ds-cen-dex"
                      v-for="(item, index) in giveLog"
                      :key="index"
                    >
                      <span>转赠人：{{ item.giverName }}</span>
                      <span>被转赠人：{{ item.beGiveName }}</span>
                      <span>转赠金额：{{ item.givAmount }}</span>
                      <span>转赠时间：{{ item.giveTime }}</span>
                      <span>转赠备注：{{ item.remarks }}</span>
                    </div>
                    <el-button slot="reference" type="text" class="ds-zzjl"
                      >转赠记录</el-button
                    >
                  </el-popover>
                </div>
              </div>
            </div>
            <div
              v-if="value2 == 'HOME_SERVICE'"
              class="ds-str"
              style="height: 100%; width: 40%"
            >
              <div id="amountRatio" style="height: 100%; width: 100%"></div>
              <span>完成进度</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ds-basic-oss" v-if="institutionNo_type == 0">
      <div class="ds-page3">
        <div class="ds-page-pk" style="padding-bottom: 1rem">
          <span class="ds-htx-x">门店信息</span>
          <div class="ds-cen-sic">
            <span class="ds-pate">所属门店：</span>
            <span class="ds-cen-wb">{{ ty_name }}</span>
          </div>
          <div class="ds-cen-sic">
            <span class="ds-pate">门店地址：</span>
            <span class="ds-cen-wb">{{ address }}</span>
          </div>
        </div>
        <div
          class="ds-page-pk"
          style="margin-top: 0.3rem; padding-bottom: 1.2rem"
        >
          <span class="ds-htx-x">储值信息</span>
          <div class="ds-cen-information">
            <div>
              <span>{{ totalBalance }}</span>
              <span>累计储值</span>
            </div>
            <div>
              <span>{{ totalBalance - balance }}</span>
              <span>累计消费金额</span>
            </div>
          </div>
          <span class="ds-htx-x">积分信息</span>
          <div class="ds-cen-information">
            <div>
              <span>{{ totalPoint }}</span>
              <span>累计积分</span>
            </div>
            <div>
              <span>{{ totalPoint - points }}</span>
              <span>累计消费积分</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="institutionNo_type == 3" class="ds-basic-oss no-datas">
      暂无数据
    </div>
  </div>
</template>
<script>
let echarts = require('echarts')
import {
  getAreaTion,
  getAreacontract,
  getAreasubsidy,
  getAreasugov,
} from '@/api/elderlyRecords/routineCheck.js'
export default {
  data() {
    return {
      giveLog: [
        {
          giverName: '',
          beGiveName: '',
          givAmount: '',
          giveTime: '',
          remarks: '',
        },
      ],
      modlist: [
        { t: '', i: '补助金额' },
        { t: '', i: '服务金额' },
        { t: '', i: '补助金额余额' },
        { t: '', i: '服务次数' },
      ],
      shipContract: [], //所属合同
      contractBusiness: [], //合同类型
      value1: '',
      value2: '',
      value: 0.5,
      institutionNo: '', //机构号
      customerGlobalId: '', //老人id
      institutionNo_type: '', //机构类型 1机构3政府
      address: '',
      ty_name: '',
      balance: '',
      points: '',
      totalBalance: '',
      totalPoint: '',
      cnglobalId: '',
    }
  },
  created() {
    this.institutionNo = this.$route.query.institutionNo
    this.customerGlobalId = this.$route.query.customerGlobalId
  },
  mounted() {
    this.getAreaTion()
  },
  methods: {
    contractValue(e) {
      this.contractBusiness.map((item) => {
        if (e == item.contractBusiness) {
          this.cnglobalId = item.globalId
          this.getAreasubsidy(item.globalId)
        }
      })
    },
    contractChange(e) {
      this.shipContract.map((item) => {
        if (item.id == e) {
          this.contractBusiness = item.contracts
        }
      })
      this.value2 = this.contractBusiness[0].contractBusiness
      this.cnglobalId = this.contractBusiness[0].globalId
      this.getAreasubsidy(this.contractBusiness[0].globalId)
    },
    getAreacontract() {
      getAreacontract(this.customerGlobalId).then((res) => {
        if (res.code == 0) {
          this.shipContract = res.data
          this.value1 = res.data[0].id
          this.contractBusiness = res.data[0].contracts
          this.value2 = this.contractBusiness[0].contractBusiness
          this.getAreasubsidy(res.data[0].contracts[0].globalId)
          this.cnglobalId = res.data[0].contracts[0].globalId
        }
      })
    },
    getAreasubsidy(data) {
      getAreasubsidy(this.customerGlobalId, data).then((res) => {
        if (res.code == 0) {
          this.modlist[0].t = res.data.subsidyAmount
          this.modlist[1].t = res.data.serviceAmount
          this.modlist[2].t = res.data.governmentAmount
          this.modlist[3].t = res.data.receivingServiceNum
          this.value = res.data.completionProgress.replace('%', '')
          this.amountRatio()
          if (res.data.giveLog != null) {
            this.giveLog.forEach((item) => {
              item.giverName = res.data.giveLog.giverName
              item.beGiveName = res.data.giveLog.beGiveName
              item.givAmount = res.data.giveLog.givAmount
              item.giveTime = res.data.giveLog.giveTime
              item.remarks = res.data.giveLog.remarks
            })
          }
        }
      })
    },
    btn_delits() {
      let name = ''
      let names = ''
      this.shipContract.map((item) => {
        if (this.value1 == item.id) {
          name = item.contractName
        }
      })
      this.contractBusiness.map((item) => {
        if (this.value2 == item.contractBusiness) {
          names = item.contractBusinessMark
        }
      })
      this.$router.push({
        path: '/elderlyMessage',
        query: {
          customerGlobalId: this.customerGlobalId,
          contractGlobalId: this.cnglobalId,
          contractBusiness: this.value2,
          contractName: name,
          contractBusinessMark: names,
        },
      })
    },
    getAreaTion() {
      getAreaTion(this.institutionNo).then((res) => {
        if (res.code == 0) {
          if (res.data.type == 3) {
            this.getAreacontract()
          } else {
            this.getAreasugov()
          }
          this.institutionNo_type = res.data.type
          this.address = res.data.address
          this.ty_name = res.data.name
        }
      })
    },
    getAreasugov() {
      getAreasugov(this.customerGlobalId, this.institutionNo).then((res) => {
        if (res.code == 0) {
          this.balance = res.data.balance
          this.points = res.data.points
          this.totalBalance = res.data.totalBalance
          this.totalPoint = res.data.totalPoint
        }
      })
    },
    amountRatio() {
      var amountRatio = echarts.init(document.getElementById('amountRatio'))
      let option = {
        title: {
          text: this.value + '%',
          x: 'center',
          y: 'center',
          textStyle: {
            fontWeight: 'normal',
            color: '#69B1FF',
            fontSize: '14',
          },
        },
        color: ['rgba(176, 212, 251, 1)'],
        series: [
          {
            type: 'pie',
            clockWise: true,
            radius: ['100%', '70%'],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            data: [
              {
                value: this.value / 100,
                name: '01',
                itemStyle: {
                  normal: {
                    color: {
                      // 完成的圆环的颜色
                      colorStops: [
                        {
                          offset: 0,
                          color: '#091A54', // 0% 处的颜色
                        },
                        {
                          offset: 0,
                          color: '#FF6351', // 100% 处的颜色
                        },
                      ],
                    },
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: '02',
                value: 1 - this.value / 100,
                itemStyle: {
                  normal: {
                    color: {
                      // 完成的圆环的颜色
                      colorStops: [
                        {
                          offset: 0,
                          color: '#091A54', // 0% 处的颜色
                        },
                        {
                          offset: 0,
                          color: '#091A54', // 100% 处的颜色
                        },
                      ],
                    },
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
            ],
          },
        ],
      }
      amountRatio.setOption(option)
      window.addEventListener('resize', function () {
        amountRatio.resize()
      })
    },
  },
}
</script>
<style scoped lang="scss">
.ds-basic-is {
  background: url('~@/assets/images/zhangzhe/bj2.png') 0 0 no-repeat;
  background-size: 100% 100%;
  width: 17.08rem;
  height: 17.58rem;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 0.5rem;
  position: relative;
}
.ds-basic-oss {
  background: url('~@/assets/images/zhangzhe/huiyuan.png') 0 0 no-repeat;
  background-size: 100% 100%;
  width: 17.08rem;
  height: 17.58rem;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 0.5rem;
  position: relative;
}
.no-datas {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #2d3769;
}
.ds-page3 {
  position: absolute;
  top: 1.66rem;
  left: 0.42rem;
  right: 0.42rem;
}
.ds-page-pk {
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  height: 6.33rem;
  padding-left: 0.67rem;
}
.ds-page-pkou {
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  height: 8.75rem;
  padding-left: 0.67rem;
}
.ds-htx-x {
  display: block;
  font-size: 0.7rem;
  font-weight: bold;
  color: #b4c6fe;
  margin-top: 0.4rem;
}
.ds-pate {
  font-size: 0.5833rem;
  font-weight: 400;
  color: #8eb8e6;
  margin-top: 0.4rem;
  opacity: 0.6;
}

::v-deep .el-input__inner {
  background: #36447d !important;
  border: 1px solid #5869ab;
  font-size: 0.5833rem;
  font-weight: 400;
  color: #8eb8e6;
}

::v-deep .el-select-dropdown__item.hover,
::v-deep .el-select-dropdown__item:hover {
  background-color: #193a6b;
}
::v-deep .el-popper[x-placement^='bottom'] .popper__arrow::after {
  top: 1px;
  margin-left: -6px;
  border-top-width: 0;
  border-bottom-color: #020c24;
}
.ds-buzhu {
  display: flex;
  align-items: center;
  margin-top: 0.67rem;
  padding-bottom: 0.4rem;
  > span:nth-of-type(1) {
    font-size: 0.7rem;
    font-weight: bold;
    color: #b4c6fe;
  }
  > span:nth-of-type(2) {
    font-size: 0.6rem;
    font-weight: 400;
    color: #ff8529;
    margin-left: 0.3rem;
  }
}
.ds-temp {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
  width: 58%;
}
.ds-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.166rem;
}
.ds-mony {
  font-size: 0.92rem;
  font-weight: bold;
  color: #b4c6fe;
  margin-top: 0.67rem;
}
.ds-cen-wb {
  font-size: 0.5833rem;
  font-weight: 400;
  color: #8eb8e6;
}
.ds-cen-sic {
  margin-top: 0.916rem;
}
.ds-ment {
  font-size: 0.5rem;
  font-weight: 400;
  color: #8eb8e6;
  margin-top: 0.3rem;
}
.ds-zzjl {
  font-size: 0.5rem;
  font-weight: 400;
  color: #ff8529;
  margin-left: 0.1rem;
}
.ds-chares {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ds-str {
  width: 30%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ds-str > span {
  font-size: 0.5rem;
  font-weight: 400;
  color: #8eb8e6;
  margin-top: 0.3rem;
}
::v-deep .el-button:hover {
  color: #ff8529;
}

.ds-cen-dex {
  display: flex;
  flex-direction: column;
}
.ds-cen-dex > span {
  margin-top: 0.33rem;
}
.el-button {
  padding: 0 !important;
}
.ds-cen-information {
  display: flex;
  align-items: center;
  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    margin-top: 0.1rem;
    > span:nth-of-type(1) {
      font-size: 1rem;
      font-weight: 500;
      color: #b4c6fe;
    }
    > span:nth-of-type(2) {
      font-size: 0.5rem;
      font-weight: 400;
      color: #8eb8e6;
      margin-top: 0.1rem;
    }
  }
  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    margin-top: 0.1rem;
    margin-left: 2.958rem;
    > span:nth-of-type(1) {
      font-size: 1rem;
      font-weight: 500;
      color: #ff8529;
    }
    > span:nth-of-type(2) {
      font-size: 0.5rem;
      font-weight: 400;
      color: #8eb8e6;
      margin-top: 0.1rem;
    }
  }
}
::v-deep [data-v-7d76e24a] .el-input__inner {
  background: #36447d !important;
  border: 1px solid #5869ab;
  font-size: 0.5833rem;
  font-weight: 400;
  color: #8eb8e6;
  height: 1.33rem;
}
</style>
<style lang="scss">
.ds-cen-popper-class {
  position: absolute;
  z-index: 1001;

  border: 1px solid #193a6b;
  border-radius: 4px;

  background: #020c24;
  /* box-shadow: 0 2px 12px 0 rgba(0,0,0,.1); */
  box-sizing: border-box;
  margin: 5px 0;
  .el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 0.33rem;
  }
  .el-popper[x-placement^='right'] .popper__arrow::after {
    bottom: -6px;
    left: 1px;
    border-right-color: #007aff;
    border-left-width: 0;
    border: 0;
    background: transparent;
  }

  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background: #172853;
  }
  .el-select-dropdown__item.selected {
    color: #8eb8e6;
    font-weight: 700;
  }
  .el-date-range-picker__content.is-left {
    border: none;
  }
  te-range-picker__header {
    color: #4dc2ff;
  }
  .el-date-table td.end-date span,
  .el-date-table td.start-date span {
    background-color: #ff9e14;
  }

  .el-date-table td.in-range div,
  .el-date-table td.in-range div:hover,
  .el-date-table.is-week-mode .el-date-table__row.current div,
  .el-date-table.is-week-mode .el-date-table__row:hover div {
    background: rgba(255, 158, 20, 0.25);
    opacity: 0.6;
    color: #ffffff !important;
  }
  .el-popper .popper__arrow::after {
    content: '';
    border-width: 0px;
  }
  .el-popper .popper__arrow {
    border-width: 0px;
  }
  .el-picker-panel__icon-btn {
    color: #4dc2ff;
  }
  .el-date-table td.disabled div {
    background-color: #0d90ff;
    opacity: 0.5;
  }
}

.el-popper .popper__arrow::after {
  content: '';
  border-width: 0px;
}
.el-popper .popper__arrow {
  border-width: 0px;
}
.el-picker-panel__icon-btn {
  color: #4dc2ff;
}
.el-date-table td.disabled div {
  background-color: #0d90ff;
  opacity: 0.5;
}
.pophover {
  width: 11.66rem !important;
  position: absolute;
  /* background: #FFF; */
  min-width: 150px;
  /* border: 1px solid #EBEEF5; */
  padding: 0 0.33rem 0.33rem 0.33rem !important;
  z-index: 2000;
  line-height: 1.4;
  text-align: justify;
  font-size: 0.583rem;
  font-weight: 400;
  color: #8eb8e6;
  box-shadow: 0 2px 0.5rem 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  background: rgba(4, 9, 41, 0.95);
  box-shadow: inset 0px 0px 24px 0px rgba(0, 122, 255, 0.81);
  border-radius: 2px;
  border: 1px solid #0c87ff;
}
</style>
