<template>
  <!-- bmi -->

  <div class="all-records">
    <!-- 查询 -->
    <time-query @changeTime="changeTime"></time-query>
    <!-- echarts图表 -->
    <!-- 心率 -->
    <div class="line-chart-box">
      <div class="emptyMessage" v-if="isData.length == 0">
        暂无数据
      </div>
      <!-- <div class="img_swiper">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in insMessage"
              :key="index"
              @click="brn_insMessage(index)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="swiper-button-prev" slot="button-prev">
            <img src="../../../imgs/zhangzhe/icon_left.png" alt="" />
          </div>
          <div  class="swiper-button-next" slot="button-next">
            <img src="../../../imgs/zhangzhe/icon_right.png" alt="" />
          </div>
        </div>
      </div> -->
      <div class="ds-cen-msse">
        <div class="ds-left">
          <img
            @click="btn_id1"
            src="@/assets/images/zhangzhe/icon_left.png"
            alt=""
          />
          <span>名称</span>
        </div>
        <div class="ds-right">
          <span>{{ insMessage[insNo].name }}</span>
          <img
            @click="btn_id2"
            src="@/assets/images/zhangzhe/icon_right.png"
            alt=""
          />
        </div>
      </div>
      <LineChart
        v-if="insNo == 0 && heartRateAbscissaData.length != 0 && flag"
        :showTimes="showTimes"
        :isAverage="true"
        :name="'心率'"
        :unit="'次/分'"
        :average="HeartRateAverage"
        :averageDesc="'次/分(正常范围：60~100次/分)'"
        :abscissaData="heartRateAbscissaData"
        :ordinateData="heartRateOrdinateData"
        :lineCartId="'HeartRate'"
        :max="100"
        :min="60"
        :whatr="insNo"
      ></LineChart>
      <!-- 体温 -->
      <LineChart
        :showTimes="showTimes"
        :isAverage="true"
        :name="'体温'"
        :unit="'°C'"
        :average="temperatureAverage"
        :averageDesc="'°C(正常范围：36.0~37.0°C)'"
        :abscissaData="temperatureAbscissaData"
        :ordinateData="temperatureOrdinateData"
        :lineCartId="'temperature'"
        :max="37"
        :min="36"
        v-if="insNo == 1 && temperatureAbscissaData.length != 0 && flag"
        :whatr="insNo"
      ></LineChart>
      <!-- 身高 -->
      <LineChart
        :showTimes="showTimes"
        :name="'身高'"
        :unit="'厘米'"
        :abscissaData="heightAbscissaData"
        :ordinateData="heightOrdinateData"
        :lineCartId="'height'"
        v-if="insNo == 2 && heightAbscissaData.length != 0 && flag"
        :whatr="insNo"
      ></LineChart>
      <!-- 体重 -->
      <LineChart
        :showTimes="showTimes"
        :name="'体重'"
        :unit="'KG'"
        :abscissaData="weightAbscissaData"
        :ordinateData="weightOrdinateData"
        :lineCartId="'weight'"
        v-if="insNo == 3 && heightAbscissaData.length != 0 && flag"
        :whatr="insNo"
      ></LineChart>
      <!-- BMI -->
      <LineChart
        :showTimes="showTimes"
        :name="'BMI'"
        :isAverage="true"
        :average="BMIAverage"
        :max="23.9"
        :min="18.5"
        :unit="'kg/cm²'"
        :averageDesc="'kg/cm²(正常范围：18.50~23.90kg/cm²)'"
        :abscissaData="BMIAbscissaData"
        :ordinateData="BMIOrdinateData"
        :lineCartId="'BMI'"
        v-if="insNo == 4 && BMIAbscissaData.length != 0 && flag"
        :whatr="insNo"
      ></LineChart>
      <!-- 基础代谢 -->
      <LineChart
        :showTimes="showTimes"
        :name="'基础代谢'"
        :isAverage="true"
        :average="metabolismAverage"
        :averageDesc="
          gender == 0
            ? 'kcal(女性正常范围：1010~1210kcal)'
            : 'kcal(男性正常范围：1350~1550kcal)'
        "
        :unit="'kcal'"
        :abscissaData="metabolismAbscissaData"
        :ordinateData="metabolismOrdinateData"
        :lineCartId="'metabolism'"
        :max="gender == 0 ? 1210 : 1550"
        :min="gender == 0 ? 1010 : 1350"
        v-if="insNo == 5 && metabolismAbscissaData.length != 0 && flag"
        :whatr="insNo"
      ></LineChart>
      <!-- 腰臀比 -->
      <LineChart
        :showTimes="showTimes"
        :name="'腰臀比'"
        :isAverage="true"
        :average="WaistToHipRatioAverage"
        :averageDesc="
          gender == 0 ? '%(女性正常范围：67~80%)' : '%(男性正常范围：85~90%)'
        "
        :unit="'%'"
        :abscissaData="WaistToHipRatioAbscissaData"
        :ordinateData="WaistToHipRatioOrdinateData"
        :lineCartId="'WaistToHipRatio'"
        :max="gender == 0 ? 80 : 90"
        :min="gender == 0 ? 67 : 85"
        v-if="insNo == 6 && WaistToHipRatioAbscissaData.length != 0 && flag"
        :whatr="insNo"
      ></LineChart>
      <!-- 体脂率 -->
      <LineChart
        :showTimes="showTimes"
        :name="'体脂率'"
        :isAverage="true"
        :average="fatPercentageAverage"
        :averageDesc="
          gender == 0 ? '%(女性正常范围：20~25%)' : '%(男性正常范围：15~18%)'
        "
        :max="gender == 0 ? 25 : 18"
        :min="gender == 0 ? 20 : 15"
        :abscissaData="fatPercentageAbscissaData"
        :ordinateData="fatPercentageOrdinateData"
        :lineCartId="'fatPercentage'"
        :unit="'%'"
        v-if="insNo == 7 && fatPercentageAbscissaData.length != 0 && flag"
        :whatr="insNo"
      ></LineChart>
      <!-- 内脏脂肪指数 -->
      <LineChart
        :showTimes="showTimes"
        :name="'内脏脂肪指数'"
        :isAverage="true"
        :average="VisceralFatAverage"
        :averageDesc="'%(正常范围：1~9%)'"
        :abscissaData="VisceralFatAbscissaData"
        :ordinateData="VisceralFatOrdinateData"
        :lineCartId="'VisceralFat'"
        :max="9"
        :unit="'%'"
        :min="1"
        v-if="insNo == 8 && VisceralFatAbscissaData.length != 0 && flag"
        :whatr="insNo"
      ></LineChart>
    </div>
  </div>
</template>

<script>
// api请求
import { getArehistory, getAreaCoter } from '@/api/elderlyRecords/routineCheck.js'
import timeQuery from '../timeQuery.vue'
import LineChart from './routineCheckLineChart.vue'
export default {
  props: {
    // 传过来的身份证号
    customerGlobalId: {
      type: [String],
      default: '',
    },
  },
  components: { timeQuery, LineChart },
  // 定义数据
  data() {
    return {
      insNo: 0,
      gender: '',
      alti: 0,
      insMessage: [
        { name: '心率', insNo: 0 },
        { name: '体温', insNo: 1 },
        { name: '身高', insNo: 2 },
        { name: '体重', insNo: 3 },
        { name: 'BMI', insNo: 4 },
        { name: '基础代谢', insNo: 5 },
        { name: '腰臀比', insNo: 6 },
        { name: '体脂率', insNo: 7 },
        { name: '内脏脂肪指数', insNo: 8 },
      ],
      // 查询表单数据
      formInline: {
        date: '',
        start: '',
        end: '',
      },
      showTimes: '',
      HeartRateAverage: '', //心率平均值
      temperatureAverage: '', //体温平均值
      BMIAverage: '', //BMI平均值
      metabolismAverage: '', //基础代谢平均值
      WaistToHipRatioAverage: '', //腰臀比平均值
      fatPercentageAverage: '', //体脂率平均值
      VisceralFatAverage: '', //内脏脂肪指数平均值
      heartRateAbscissaData: [], //心率横坐标数据
      heartRateOrdinateData: [], //心率纵坐标数据
      temperatureAbscissaData: [], //体温横坐标数据
      temperatureOrdinateData: [], //体温纵坐标数据
      heightAbscissaData: [], //身高横坐标数据
      heightOrdinateData: [], //身高纵坐标数据
      weightAbscissaData: [], //体重横坐标数据
      weightOrdinateData: [], //体重纵坐标数据
      BMIAbscissaData: [], //BMI横坐标数据
      BMIOrdinateData: [], //BMI纵坐标数据
      metabolismAbscissaData: [], //基础代谢横坐标数据
      metabolismOrdinateData: [], //基础代谢纵坐标数据
      WaistToHipRatioAbscissaData: [], //腰臀比横坐标数据
      WaistToHipRatioOrdinateData: [], //腰臀比纵坐标数据
      fatPercentageAbscissaData: [], //体脂率横坐标数据
      fatPercentageOrdinateData: [], //体脂率纵坐标数据
      VisceralFatAbscissaData: [], //内脏脂肪指数横坐标数据
      VisceralFatOrdinateData: [], //内脏脂肪指数纵坐标数据
      flag: true,
      isData: [], // 是否有数据
    }
  },
  // dom加载
  mounted() {
    this.getAreaCoter()
  },
  // 定义函数
  methods: {
    btn_id1() {
      if (this.insNo != 0) {
        this.insNo--
      }
    },
    btn_id2() {
      if (8 >= this.insNo) {
        this.insNo++
      }
    },
    getAreaCoter() {
      getAreaCoter(this.customerGlobalId).then((res) => {
        this.gender = res.data.gender
      })
    },
    changeTime(data) {
      const { date, showTimes, start, end } = data
      this.showTimes = showTimes
      this.formInline.date = date
      this.formInline.start = start
      this.formInline.end = end
      this.init()
    },
    async init(start, end) {
      this.flag = false
      const res = await getArehistory({
        customerGlobalId: this.customerGlobalId,
        date: this.formInline.date,
        type: 'bodyMassIndex',
        start: this.formInline.start,
        end: this.formInline.end,
      })
      console.log(res)
      if (res.code == 0) {
        //处理数据
        this.isData = res.data.collectionTime
        // 心率
        this.heartRateAbscissaData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.heartRate)
        ).abscissaData
        this.heartRateOrdinateData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.heartRate)
        ).ordinateData
        this.HeartRateAverage = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.heartRate)
        ).mean
        // 体温
        this.temperatureAbscissaData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.bodyTemperature)
        ).abscissaData
        this.temperatureOrdinateData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.bodyTemperature)
        ).ordinateData
        this.temperatureAverage = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.bodyTemperature)
        ).mean
        // 身高
        this.heightAbscissaData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.height)
        ).abscissaData
        this.heightOrdinateData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.height)
        ).ordinateData
        //体重
        this.weightAbscissaData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.weight)
        ).abscissaData
        this.weightOrdinateData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.weight)
        ).ordinateData
        //BMI
        this.BMIAbscissaData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.bodyMassIndex)
        ).abscissaData
        this.BMIOrdinateData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.bodyMassIndex)
        ).ordinateData
        this.BMIAverage = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.bodyMassIndex)
        ).mean
        console.log(this.BMIAbscissaData, this.BMIOrdinateData, this.BMIAverage)
        //基础代谢
        this.metabolismAbscissaData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.metabolize)
        ).abscissaData
        this.metabolismOrdinateData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.metabolize)
        ).ordinateData
        this.metabolismAverage = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.metabolize)
        ).mean
        //腰臀比
        this.WaistToHipRatioAbscissaData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.waistHipRatio)
        ).abscissaData
        this.WaistToHipRatioOrdinateData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.waistHipRatio)
        ).ordinateData
        this.WaistToHipRatioAverage = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.waistHipRatio)
        ).mean
        //体脂率
        this.fatPercentageAbscissaData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.bodyFatRatio)
        ).abscissaData
        this.fatPercentageOrdinateData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.bodyFatRatio)
        ).ordinateData
        this.fatPercentageAverage = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.bodyFatRatio)
        ).mean
        //内脏脂肪指数
        this.VisceralFatAbscissaData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.visceralFat)
        ).abscissaData
        this.VisceralFatOrdinateData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.visceralFat)
        ).ordinateData
        this.VisceralFatAverage = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.visceralFat)
        ).mean
        this.flag = true
        console.log(this.VisceralFatAverage, this.VisceralFatOrdinateData, 52)
      }
    },
    processingData(abscissaData, ordinateData) {
      ordinateData.forEach((item, index) => {
        if (item == null) {
          abscissaData[index] = null
        }
      })
      ordinateData = ordinateData.filter(function(i) {
        return i
      })
      abscissaData = abscissaData.filter(function(i) {
        return i
      })
      let mean
      if (ordinateData !== null && ordinateData.length != 0) {
        mean = _.mean(ordinateData)
      }
      return {
        abscissaData: abscissaData,
        ordinateData: ordinateData,
        mean: mean,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.all-records {
  position: relative;
}
.emptyMessage {
  position: absolute;
  font-size: 1.2rem;
  color: #2d3769;
  top: 9rem;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img_swiper {
  width: 100px;
  height: 30px;
  background: #18214a;
  margin-right: 0.4166rem;
  line-height: 30px;
  position: absolute;
  right: 0;
  bottom: 10px;
}
.swiper-button-prev,
.swiper-button-next {
  margin-top: -12px !important;
}
.swiper-button-next {
  position: absolute;
  right: -12px !important;
}
.swiper-wrapper {
  text-align: center !important;
  color: #b4c6fe !important;
}
.ds-cen-msse {
  display: flex;
  position: absolute;
  right: 0.416rem;

  .ds-left {
    display: flex;
    align-items: center;
    padding: 0 0.3rem 0 0.2rem;
    background: #18214a;
    span {
      color: #b4c6fe;
    }
  }
  .ds-right {
    margin-left: 0.3rem;
    display: flex;
    align-items: center;
    width: 100px;
    height: 30px;
    background: #18214a;
    img {
      margin-left: 1rem;
    }
    span {
      color: #b4c6fe;
      margin-left: 1rem;
    }
  }
}
</style>
