<template>
  <!-- 血常规 -->

  <div class="all-records">
    <!-- 查询 -->
    <time-query @changeTime="changeTime"></time-query>
    <!-- echarts图表 -->
    <div class="line-chart-box">
      <!-- 血红蛋白 -->
      <div class="emptyMessage" v-if="isData.length == 0">
        暂无数据
      </div>
      <LineChart
        :showTimes="showTimes"
        :isAverage="true"
        :name="'血红蛋白'"
        :unit="'g/L'"
        :average="hemoglobinAverage"
        :averageDesc="
          gender == 0
            ? 'g/L(女性正常范围：110g/L~150g/L)'
            : 'g/L(男性正常范围：120g/L~160g/L)'
        "
        :abscissaData="hemoglobinAbscissaData"
        :ordinateData="hemoglobinOrdinateData"
        :lineCartId="'hemoglobin'"
        :max="gender == 0 ? 150 : 160"
        :min="gender == 0 ? 110 : 120"
        v-if="hemoglobinAbscissaData.length != 0 && flag"
      ></LineChart>
      <!-- 血氧饱和度 -->
      <LineChart
        :showTimes="showTimes"
        :isAverage="true"
        :name="'血氧饱和度'"
        :unit="'%'"
        :average="BloodOxygenAverage"
        :averageDesc="'%(正常范围：95%-100%)'"
        :abscissaData="BloodOxygenAbscissaData"
        :ordinateData="BloodOxygenOrdinateData"
        :lineCartId="'BloodOxygen'"
        :max="100"
        :min="95"
        v-if="BloodOxygenAbscissaData.length != 0 && flag"
      ></LineChart>
    </div>
  </div>
</template>

<script>
// api请求
import { getArehistory, getAreaCoter } from '@/api/elderlyRecords/routineCheck.js'
import timeQuery from '../timeQuery.vue'
import LineChart from './routineCheckLineChart.vue'
export default {
  props: {
    // 传过来的身份证号
    customerGlobalId: {
      type: [String],
      default: '',
    },
  },
  components: { timeQuery, LineChart },
  // 定义数据
  data() {
    return {
      // 查询表单数据
      formInline: {
        date: '',
      },
      showTimes: '',
      hemoglobinAverage: '', //血红蛋白平均值
      BloodOxygenAverage: '', //血氧饱和度平均值
      hemoglobinAbscissaData: [], //血红蛋白横坐标数据
      hemoglobinOrdinateData: [], //血红蛋白纵坐标数据
      BloodOxygenAbscissaData: [], //血氧饱和度横坐标数据
      BloodOxygenOrdinateData: [], //血氧饱和度纵坐标数据
      flag: true,
      gender: '',
      isData: [], // 是否有数据
    }
  },
  // dom加载
  mounted() {
    this.getAreaCoter()
  },
  // 定义函数
  methods: {
    getAreaCoter() {
      getAreaCoter(this.customerGlobalId).then((res) => {
        this.gender = res.data.gender
      })
    },
    changeTime(data) {
      const { date, showTimes, start, end } = data
      this.showTimes = showTimes
      this.formInline.date = date
      this.init(start, end)
    },
    async init(start, end) {
      this.flag = false
      const res = await getArehistory({
        customerGlobalId: this.customerGlobalId,
        date: this.formInline.date,
        type: 'bloodTesting',
        start,
        end,
      })
      console.log(res)
      if (res.code == 0) {
        //处理数据
        this.isData = res.data.collectionTime
        // 心率
        this.hemoglobinAbscissaData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.hemoglobin)
        ).abscissaData
        this.hemoglobinOrdinateData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.hemoglobin)
        ).ordinateData
        this.hemoglobinAverage = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.hemoglobin)
        ).mean
        // 血氧饱和度
        this.BloodOxygenAbscissaData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.bloodOxygen)
        ).abscissaData
        this.BloodOxygenOrdinateData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.bloodOxygen)
        ).ordinateData
        this.BloodOxygenAverage = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.bloodOxygen)
        ).mean
        this.flag = true
      }
    },
    processingData(abscissaData, ordinateData) {
      ordinateData.forEach((item, index) => {
        if (item == null) {
          abscissaData[index] = null
        }
      })
      ordinateData = ordinateData.filter(function(i) {
        return i
      })
      abscissaData = abscissaData.filter(function(i) {
        return i
      })
      let mean
      if (ordinateData !== null && ordinateData.length != 0) {
        mean = _.mean(ordinateData)
      }
      return {
        abscissaData: abscissaData,
        ordinateData: ordinateData,
        mean: mean,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.all-records {
  position: relative;
}
.emptyMessage {
  position: absolute;
  font-size: 1.2rem;
  color: #2d3769;
  top: 9rem;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
