<template>
  <div class="line-charts-box">
    <div class="line-charts-box-box">
      <div class="line-chart-time">
        <span>{{ name }}</span>
        <span>{{ showTimes }}</span>
      </div>
      <div class="line-chart_average">{{ average }} {{ averageDesc }}</div>

      <div
        :id="lineCartId"
        style="width: 100%; height:13rem; margin-top: 15px"
      ></div>
    </div>
  </div>
</template>

<script>
//引入echarts
let echarts = require('echarts')
import warningImage from '@/assets/images/zhangzhe/warning.png'
export default {
  props: {
    name: {
      default: '',
      type: String,
    }, // 名字（折线图名字）
    isAverage: {
      default: false,
      type: Boolean,
    }, //是否有平均值
    average: {
      default: '',
      type: [String, Number],
    }, //平均值数
    averageDesc: {
      default: '',
      type: String,
    }, //平均值介绍
    unit: {
      default: '',
      type: String,
    }, //单位
    abscissaData: {
      default: [],
      type: Array,
    }, // 横坐标数据
    ordinateData: {
      default: [],
      type: Array,
    }, //纵坐标数据
    showTimes: {
      default: '',
      type: String,
    }, //日期名字
    lineCartId: {
      default: '',
      type: String,
    }, //折线图id名字
    max: {
      default: null,
      type: [String, Number],
    },
    min: {
      default: null,
      type: [String, Number],
    },
    whatr: {
      default: null,
      type: [String, Number],
    },
  },
  watch: {
    // 被侦听的变量count
    whatr() {
        setTimeout(() => {
      this.init()
    }, 500)
    },
  },
  mounted() {
    setTimeout(() => {
      this.init()
    }, 500)
  },
  methods: {
    init() {
      this.$nextTick(() => {
        let myChart = echarts.init(document.getElementById(this.lineCartId))
        let option = {
          //dataZoom-inside 内置型数据区域缩放组件 所谓内置 1平移：在坐标系中滑动拖拽进行数据区域平移。2缩放：PC端：鼠标在坐标系范围内滚轮滚动（MAC触控板类同;移动端：在移动端触屏上，支持两指滑动缩放。
          dataZoom: [
            {
              //   type: 'inside', //1平移 缩放
              throttle: '50', //设置触发视图刷新的频率。单位为毫秒（ms）。
              minValueSpan: 5, //用于限制窗口大小的最小值,在类目轴上可以设置为 5 表示 5 个类目
              start: 0, //数据窗口范围的起始百分比 范围是：0 ~ 100。表示 0% ~ 100%。
              end: 50, //数据窗口范围的结束百分比。范围是：0 ~ 100。
              zoomLock: true, //如果设置为 true 则锁定选择区域的大小，也就是说，只能平移，不能缩放。
              animation: true,
              type: 'slider',
              show: this.abscissaData.length > 6 ? true : false,
              zoomLock: true,
              //   orient: 'vertical',
              filterMode: 'none',
              right: '2%',
              bottom: '5%',
              textStyle: {
                color: '#FFF',
                fontSize: 0,
              },
              height: 5,
              fillerColor: '#FF8529',
              brushSelect: false,
              handleSize: '100%',
              handleStyle: {
                color: '#FF8529',
                borderWidth: 0,
              },
              backgroundColor: 'rgba(38, 108, 252, 0.1)', //两边未选中的滑动条区域的颜色
              borderColor: 'transparent',
              filterMode: 'filter',
            },
            {
              type: 'inside', //1平移 缩放
              throttle: '50', //设置触发视图刷新的频率。单位为毫秒（ms）。
              minValueSpan: 5, //用于限制窗口大小的最小值,在类目轴上可以设置为 5 表示 5 个类目
              start: 1, //数据窗口范围的起始百分比 范围是：0 ~ 100。表示 0% ~ 100%。
              end: 50, //数据窗口范围的结束百分比。范围是：0 ~ 100。
              zoomLock: true, //如果设置为 true 则锁定选择区域的大小，也就是说，只能平移，不能缩放。
            },
          ],
          // 主要用来控制图表四周留白
          tooltip: {
            trigger: 'axis',
            //   showContent: false, //关闭原有样式
            enterable: true,
            backgroundColor: '#fff', //设置背景图片 rgba格式
            axisPointer: {
              lineStyle: {
                width: 3,
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#FF8529', // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: '#FF8529', // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#FF8529', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            padding: 0,
            //   position: 'top',
            formatter: (params) => {
              //return回调一个模板字符串，自定义提示框的形状
              // console.log(params)
              return `
              <div class="HeartRate_tooltip">
                <div class="left"></div>
                <div class='HeartRate_name'>${params[0].name}</div>
                <div class='HeartRate_line'></div>
                <div class='count'>
                    <span>${this.name}:</span>
                    <span>${params[0].data}${this.unit}</span>
                </div>
            </div>
                `
            },
          },
          grid: {
            //调整地图位置
            left: '0%',
            right: '5%',
            bottom: '0%',
            top: '11%',
            containLabel: true,
          },
          xAxis: {
            //横坐标样式
            axisTick: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: '#8EB8E6',
                fontSize: 12,
              },
            },
            data: this.abscissaData,
            axisLine: {
              lineStyle: {
                color: '#394788',
              },
            },
          },
          yAxis: {
            name: this.unit,
            nameGap: 18,
            nameTextStyle: {
              color: '#8C8C8C',
              fontSize: 14,
              align: 'left',
              padding: [0, 0, 0, -25],
            },
            axisTick: {
              show: false,
            },
            //纵坐标样式
            splitLine: {
              show: true,
              lineStyle: { type: 'dashed', color: '#394788' },
            },
            axisLabel: {
              textStyle: {
                color: '#8EB8E6',
                fontSize: 14,
              },
            },
            axisLine: {
              lineStyle: {
                color: '#394788',
              },
            },
          },
          graphic: [
            //地图的背景  这里放的是图片，也可以是纯颜色背景
            {
              type: 'image',
              style: {
                backgroundColor: '#ccc',
                x: 70,
                y: 35,
                width: 680,
                height: 220,
              },
            },
          ],
          series: [
            {
              name: '数量',
              type: 'line',
              showSymbol: true,
              symbolSize: (params) => {
                if (this.max && this.min) {
                  if (params < this.min || params > this.max) {
                    return 10
                  } else {
                    return 8
                  }
                } else {
                  return 8
                }
              },
              symbol: 'circle', //拐点的形状
              itemStyle: {
                normal: {
                  lineStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      //设置折线渐变色
                      { offset: 0, color: '#FF7B3A' },
                      { offset: 1, color: '#FF7B3A' },
                    ]),
                  },
                  color: (params) => {
                    if (this.max && this.min) {
                      if (params.value > this.max || params.value < this.min) {
                        return 'red'
                      } else {
                        return '#FF7B3A'
                      }
                    } else {
                      return '#FF7B3A'
                    }
                  }, //这里设置的拐点颜色
                },
              },
              label: {
                show: true,
                distance: 3,
                backgroundColor: 'rgba(255,213,213,0.65)',
                borderColor: '#FF7A7A', //外层边框颜色
                borderWidth: 0.5, //外层边框宽度
                borderRadius: 2, //外层边框圆角
                formatter: (params) => {
                  if (this.max && this.min) {
                    if (params.value > this.max || params.value < this.min) {
                      return '{a|}{img|}{leftGap|}{value|' + params.value + '}'
                    } else {
                      return ''
                    }
                  } else {
                    return ''
                  }
                },
                rich: {
                  a: {
                    height: 24,
                    width: 2,
                    padding: [0, 8, 0, 0],
                  },
                  leftGap: { height: 24, width: 2 },
                  img: {
                    backgroundColor: {
                      image: warningImage,
                    },
                    align: 'right',
                    height: 13,
                  },
                  value: {
                    height: 20,
                    fontSize: 12,
                    color: '#FF4D4F',
                    padding: [2, 8, 0, 0],
                  },
                  num: {
                    borderWidth: 0,
                    borderRadius: 0,
                    color: '#595959',
                  },
                },
              },
              emphasis: {},
              data: this.ordinateData,
            },
            {
              name: '数量',
              type: 'line',
              showSymbol: true,
              symbolSize: (params) => {
                if (this.max && this.min) {
                  if (params < this.min || params > this.max) {
                    return 10
                  } else {
                    return 8
                  }
                } else {
                  return 8
                }
              },
              symbol: 'circle', //拐点的形状
              itemStyle: {
                normal: {
                  lineStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      //设置折线渐变色
                      { offset: 0, color: '#FF7B3A' },
                      { offset: 1, color: '#FF7B3A' },
                    ]),
                  },
                  color: (params) => {
                    if (this.max && this.min) {
                      if (params.value > this.max || params.value < this.min) {
                        return 'red'
                      } else {
                        return '#FF7B3A'
                      }
                    } else {
                      return '#FF7B3A'
                    }
                  }, //这里设置的拐点颜色
                },
              },
              label: {
                show: true,
                distance: 3,
                formatter: (params) => {
                  if (this.max && this.min) {
                    if (params.value > this.max || params.value < this.min) {
                      return ''
                    } else {
                      return '{num|}' + params.value
                    }
                  } else {
                    return '{num|}' + params.value
                  }
                },
                rich: {
                  num: {
                    borderWidth: 0,
                    borderRadius: 0,
                    color: '#595959',
                  },
                },
              },
              emphasis: {},
              data: this.ordinateData,
            },
          ],
        }
        myChart.setOption(option)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.line-charts-box-box {
  height: 16rem;
  background: #050f33;
  border-radius: 2px;
  margin-top: 16px;
}
.line-chart_name {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 550;
  color: #262626;
}
.line-chart_average_title {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8c8c8c;
  margin-top: 12px;
}
.line-chart_average {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400;
  color: #ff8529;
  line-height: 28px;
  margin-top: 10px;
}
.line-chart-time {
  display: flex;
  align-items: center;
  > span:nth-of-type(1) {
    font-size: 16px;
    font-weight: bold;
    color: #b4c6fe;
  }
  > span:nth-of-type(2) {
    font-weight: 400;
    color: #8eb8e6;
    margin-left: 0.3rem;
  }
}

::v-deep .HeartRate_tooltip {
  background: url('~@/assets/images/top/fuchuang.png');
  background-size: 100% 100%;
  width: 7.916rem;
  height: 2.916rem;
}
.HeartRate_name1 {
  font-weight: 400;
  color: #8eb8e6;
}
.HeartRate_name2 {
  font-weight: 500;
  color: #ffffff;
}
::v-deep .HeartRate_tooltip {
  width: 160px;
  height: 70px;
  background: #050f33;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #0854ac;
  .HeartRate_name {
    padding-left: 8px;
    margin-top: -10px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 550;
    color: #8eb8e6;
  }
  .count {
    margin-left: 8px;
    width: 100%;
    // padding-right: 8px;
    span {
      &:nth-child(1) {
        float: left;
      }
      &:nth-child(2) {
        margin-left: 0.3rem;
      }
    }
  }
  .left {
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-right-color: #060928;
    position: relative;
    top: 25px;
    left: -17px;
  }
}
</style>
