import request from '@/utils/request'
//   健康档案折线图
export const getArehistory = (data) =>
  request({
    url: `/hp/platform/data/v1/screen/gov/customer/history?customerGlobalId=${data.customerGlobalId}&date=${data.date}&type=${data.type}&start=${data.start}&end=${data.end}`,
    method: 'get',
  })
//   长者健康档案基础资料
export const getAreaCoter = (code) =>
  request({
    url:
      '/hp/platform/data/v1/screen/gov/customer/detail/info?customerGlobalId=' +
      code,
    method: 'get',
  })
// 获取长者评估等级
export const getAreaassessment = (code) =>
  request({
    url:
      '/hp/platform/data/v1/screen/gov/customer/assessment?customerGlobalId=' +
      code,
    method: 'get',
  })
//   查询机构类型
export const getAreaTion = (code) =>
  request({
    url:
      '/hp/platform/data/v1/screen/gov/institution/info?institutionNo=' + code,
    method: 'get',
  })
//   长者信息查询合同列表
export const getAreacontract = (code) =>
  request({
    url:
      '/hp/platform/data/v1/screen/gov/customer/contract/list?customerGlobalId=' +
      code,
    method: 'get',
  })
//   老人id和合同id查询补助信息
export const getAreasubsidy = (code, data) =>
  request({
    url: `/hp/platform/data/v1/screen/gov/contract/screen/customer_subsidy_info?customerGlobalId=${code}&contractGlobalId=${data}`,
    method: 'get',
  })
//   查询老人会员账户信息
export const getAreasugov = (code, data) =>
  request({
    url: `/hp/platform/data/v1/screen/gov/customer/vip/info?customerGlobalId=${code}&institutionNo=${data}`,
    method: 'get',
  })
//   长者数据-健康档案
export const getAreachives = (code) =>
  request({
    url:
      '/hp/platform/data/v1/screen/gov/customer/health-archives?customerGlobalId=' +
      code,
    method: 'get',
  })
//   老人订单统计
export const getAreasugovtome = (data) =>
  request({
    url: `/hp/platform/data/v1/screen/gov?customerGlobalId=${data.customerGlobalId}&type=${data.type}`,
    method: 'get',
  })
//   订单分页
export const getAreasuorder = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/order-page',
    method: 'post',
    data,
  })
//   生活服务/餐饮订单/订单分页
export const getAreacommon = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/common/order/page',
    method: 'post',
    data,
  })
//   查询预警统计
export const getAstatistics = (data) =>
  request({
    url:
      '/hp/platform/data/v1/screen/gov/job/warn/statistics?customerGlobalId=' +
      data,
    method: 'get',
  })
//   查询预警分页
export const getAstatispage = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/job/list?customerGlobalId=' + data,
    method: 'get',
  })
