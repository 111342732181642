<template>
  <!-- 血压 -->

  <div class="all-records">
    <!-- 查询 -->
    <time-query @changeTime="changeTime"></time-query>
    <!-- echarts图表 -->
    <div class="line-chart-box">
      <div class="emptyMessage" v-if="isData.length == 0">
        暂无数据
      </div>
      <LineChart
        :showTimes="showTimes"
        :name="'血压'"
        :unit="'mmHg'"
        :lineCartId="'bloodPressure'"
        :lineData="bloodPressure"
        :abscissaData="abscissaData"
        v-if="abscissaData.length != 0 && flag"
      ></LineChart>
    </div>
  </div>
</template>

<script>
// api请求
import { getArehistory, getAreaCoter } from '@/api/elderlyRecords/routineCheck.js'
import timeQuery from '../timeQuery.vue'
import LineChart from './compon/bloodPressure.vue'
export default {
  props: {
    // 传过来的身份证号
    customerGlobalId: {
      type: [String],
      default: '',
    },
  },
  components: { timeQuery, LineChart },
  // 定义数据
  data() {
    return {
      // 查询表单数据
      formInline: {
        date: '',
      },
      showTimes: '',
      flag: true,
      abscissaData: [],
      bloodPressure: [
        {
          name: '舒张压',
          range: 'mmHg(正常范围：90mmHg-120mmHg)',
          max: 120,
          min: 90,
          color: '#FF8529',
          average: '',
          data: [],
        },
        {
          name: '收缩压',
          range: 'mmHg(正常范围：60mmHg-80mmHg)',
          max: 80,
          min: 60,
          color: '#0587AF',
          average: '',
          data: [],
        },
      ],
      isData: [], // 是否有数据
    }
  },
  // dom加载
  mounted() {},
  // 定义函数
  methods: {
    changeTime(data) {
      console.log(data, 555555)
      const { date, showTimes, start, end } = data
      this.showTimes = showTimes
      this.formInline.date = date
      this.init(start, end)
    },
    async init(start, end) {
      this.flag = false
      const res = await getArehistory({
        customerGlobalId: this.customerGlobalId,
        date: this.formInline.date,
        type: 'bloodPressure',
        start,
        end,
      })
      console.log(res)
      if (res.code == 0) {
        //处理数据
        this.flag = false
        this.isData = res.data.collectionTime
        //横坐标
        this.abscissaData = res.data.collectionTime
        //舒张压
        const diastolicBloodPressureAverage = _.mean(
          res.data.diastolicBloodPressure
        )
        const systolicBloodPressureAverage = _.mean(
          res.data.systolicBloodPressure
        )
        this.bloodPressure[0].average = diastolicBloodPressureAverage
        this.bloodPressure[1].average = systolicBloodPressureAverage
        this.bloodPressure[0].data = res.data.diastolicBloodPressure
        this.bloodPressure[1].data = res.data.systolicBloodPressure
        //收缩压
        this.flag = true
        console.log(this.abscissaData, this.bloodPressure, 966666666)
      }
    },
    processingData(abscissaData, ordinateData) {
      ordinateData.forEach((item, index) => {
        if (item == null) {
          abscissaData[index] = null
        }
      })
      ordinateData = ordinateData.filter(function(i) {
        return i
      })
      abscissaData = abscissaData.filter(function(i) {
        return i
      })
      let mean
      if (ordinateData !== null && ordinateData.length != 0) {
        mean = _.mean(ordinateData)
      }
      return {
        abscissaData: abscissaData,
        ordinateData: ordinateData,
        mean: mean,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.all-records {
  position: relative;
}
.emptyMessage {
  position: absolute;
  font-size: 1.2rem;
  color: #2d3769;
  top: 9rem;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
