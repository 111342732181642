<template>
  <!-- 血脂 -->
  <div class="all-records">
    <!-- 查询 -->
    <time-query @changeTime="changeTime"></time-query>
    <!-- echarts图表 -->
    <div class="line-chart-box" style="margin-top: 0.3rem;">
      <div class="emptyMessage" v-if="isData.length == 0">
        暂无数据
      </div>
      <LineChart
        :showTimes="showTimes"
        :name="'血脂'"
        :unit="'mmol/L'"
        :lineCartId="'bloodFat'"
        :lineData="bloodFat"
        :abscissaData="abscissaData"
        v-if="abscissaData.length != 0 && flag"
      ></LineChart>
    </div>
  </div>
</template>

<script>
// api请求
import { getArehistory, getAreaCoter } from '@/api/elderlyRecords/routineCheck.js'
import timeQuery from '../timeQuery.vue'
import LineChart from './compon/bloodFat.vue'
export default {
  props: {
    // 传过来的身份证号
    customerGlobalId: {
      type: [String],
      default: '',
    },
    gender: {
      type: [String, Number],
    },
  },
  components: { timeQuery, LineChart },
  // 定义数据
  data() {
    return {
      // 查询表单数据
      formInline: {
        date: '',
      },
      showTimes: '',
      flag: true,
      abscissaData: [],
      bloodFat: [
        {
          name: '总胆固醇',
          range: 'mmol/L(正常范围：2.80mmol/L-5.17mmol/L)',
          max: 5.17,
          min: 2.8,
          color: '#FF8529',
          average: '',
          data: [],
        },
        {
          name: '甘油三酯',
          range: 'mmol/L(正常范围：0.56mmol/L-1.70mmol/L)',
          max: 1.7,
          min: 0.56,
          color: '#0587AF',
          average: '',
          data: [],
        },
        {
          name: 'HDL',
          range: 'mmol/L(男性正常范围：0.96mmol/L-1.15mmol/L)',
          max: 1.15,
          min: 0.96,
          color: '#3C9C0D',
          average: '',
          data: [],
        },
        {
          name: 'LDL',
          range: 'mmol/L(正常范围：0mmol/L-3.10mmol/L)',
          max: 3.1,
          min: 0,
          color: '#C61AC5',
          average: '',
          data: [],
        },
      ],
      isData: [], // 是否有数据
    }
  },
  // dom加载
  mounted() {},
  // 定义函数
  methods: {
    changeTime(data) {
      const { date, showTimes, start, end } = data
      this.showTimes = showTimes
      this.formInline.date = date
      this.init(start, end)
    },
    async init(start, end) {
      this.flag = false
      const res = await getArehistory({
        customerGlobalId: this.customerGlobalId,
        date: this.formInline.date,
        type: 'bloodFat',
        start,
        end,
      })
      console.log(res)
      if (res.code == 0) {
        this.gender == 0
          ? (this.bloodFat[2] = {
              name: 'HDL',
              range: 'mmol/L(女性正常范围：0.90mmol/L-1.55mmol/L)',
              max: 1.55,
              min: 0.9,
              color: '#3C9C0D',
              average: '',
              data: [],
            })
          : (this.bloodFat[2] = {
              name: 'HDL',
              range: 'mmol/L(男性正常范围：0.96mmol/L-1.15mmol/L)',
              max: 1.15,
              min: 0.96,
              color: '#3C9C0D',
              average: '',
              data: [],
            })
        //处理数据
        this.flag = false
        this.isData = res.data.collectionTime
        //横坐标
        this.abscissaData = res.data.collectionTime
        //总胆固醇
        const totalCholesterolAverage = _.mean(
          _.cloneDeep(
            res.data.totalCholesterol.filter((item) => {
              return item
            })
          )
        )
        // 甘油三酯
        const triglycerideAverage = _.mean(
          _.cloneDeep(
            res.data.triglyceride.filter((item) => {
              return item
            })
          )
        )
        //HDL
        const highDensityProteinAverage = _.mean(
          _.cloneDeep(
            res.data.highDensityProtein.filter((item) => {
              return item
            })
          )
        )
        //LDL
        const lowDensityProteinAverage = _.mean(
          _.cloneDeep(
            res.data.lowDensityProtein.filter((item) => {
              return item
            })
          )
        )
        this.bloodFat[0].average = totalCholesterolAverage
        this.bloodFat[1].average = triglycerideAverage
        this.bloodFat[2].average = highDensityProteinAverage
        this.bloodFat[3].average = lowDensityProteinAverage
        this.bloodFat[0].data = res.data.totalCholesterol
        this.bloodFat[1].data = res.data.triglyceride
        this.bloodFat[2].data = res.data.highDensityProtein
        this.bloodFat[3].data = res.data.lowDensityProtein
        //收缩压
        this.flag = true
      }
    },
    processingData(abscissaData, ordinateData) {
      ordinateData.forEach((item, index) => {
        if (item == null) {
          abscissaData[index] = null
        }
      })
      ordinateData = ordinateData.filter(function(i) {
        return i
      })
      abscissaData = abscissaData.filter(function(i) {
        return i
      })
      let mean
      if (ordinateData !== null && ordinateData.length != 0) {
        mean = _.mean(ordinateData)
      }
      return {
        abscissaData: abscissaData,
        ordinateData: ordinateData,
        mean: mean,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.all-records {
  position: relative;
}
.emptyMessage {
  position: absolute;
  font-size: 1.2rem;
  color: #2d3769;
  top: 9rem;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
