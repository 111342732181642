<template>
  <div>
    <div class="ds-basic-pp">
      <div class="ds-cen-fover" v-if="institutionNo_type == 0">
        <div
          :class="t_service == index ? 't_service1' : 't_service2'"
          v-for="(item, index) in Shcander"
          :key="index"
          @click="btn_service(index, item.value)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="ds-page2">
        <div class="ds-cen-oss">
          <div v-for="(item, index) in list" :key="index">
            <div class="ds-cen-older">
              <span :class="index == 1 ? 'ds-can-ang' : 'ds-can-ang1'">{{
                item.t
              }}</span>
              <span class="ds-can-ang2">{{ item.i }}</span>
            </div>
          </div>
        </div>
        <el-table
          :data="tableData"
          :cell-style="{ borderColor: '#213060' }"
          style="width: 100%;margin-top: 0.21rem;height: 13.9rem;padding-left: 0.2rem;padding-right: 0.2rem;"
          :header-cell-style="{ background: '#152452' }"
        >
          <el-table-column prop="orderNo" label="订单编号" width="200">
          </el-table-column>
          <el-table-column
            prop="serviceName"
            :show-overflow-tooltip="true"
            label="服务项目"
          >
          </el-table-column>
          <el-table-column prop="createTime" label="服务时间">
          </el-table-column>
          <el-table-column prop="payAmount" label="订单金额" width="80">
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="6"
            layout="total, prev, pager, next, jumper"
            :total="total"
            background
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="ds-page-li">
      <div class="ds-page2" style="border: none;">
        <div class="ds-cen-oss">
          <div v-for="(item, index) in list_oss" :key="index">
            <div class="ds-cen-older">
              <span :class="index == 1 ? 'ds-can-ale' : 'ds-can-ang1'">{{
                item.t
              }}</span>
              <span class="ds-can-ang2">{{ item.i }}</span>
            </div>
          </div>
          <div class="ds-wanchen-ok">
            <div class="ds-wanchen-ok1">
              <span>{{ percent }}%</span>
              <span>完成率</span>
            </div>
            <div id="amoun" style="height: 80%; width: 30%;"></div>
          </div>
        </div>
        <vue-seamless-scroll
          class="left-text"
          :class-option="defaultOption"
          :data="message_list"
        >
          <ul class="ds-scoll-age">
            <li
              v-for="(item, index) in message_list"
              :key="index"
              @click="btn_message(item)"
            >
              <el-popover placement="top-start" popper-class="pophoveracx">
                <div slot="reference">
                  <div class="ds-scoll-age1">
                    <div class="ds-scoll-age2">
                      <span class="ds-apan1">
                        生命体征监测：{{ item.warnContent }}</span
                      >
                      <span class="ds-apan2"
                        >{{ item.deviceName }}(爱牵挂/{{
                          item.deviceType
                        }})</span
                      >
                    </div>
                    <span :class="item.state == 2 ? 'ds-span3' : 'ds-span4'">{{
                      item.stateMark
                    }}</span>
                  </div>
                  <div class="ds-scoll-age3">
                    <span>{{ item.warnTime }}</span>
                    <span>{{ item.warnAddress }}</span>
                  </div>
                </div>
                <div class="ds-cen-mssge">
                  <div class="ds-cen-warning">
                    <span>预警次数</span>
                    <span>{{ item.deviceAlarms.length }}次</span>
                  </div>
                  <div class="ds-cen-munia">
                    <div
                      class="ds-cen-ment"
                      v-for="(item, index) in item.deviceAlarms"
                      :ken="index"
                    >
                      {{ item.time }}
                    </div>
                  </div>
                </div>
              </el-popover>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>
<script>
let echarts = require('echarts')
import {
  getAreasugovtome,
  getAreaTion,
  getAreasuorder,
  getAreacommon,
  getAstatistics,
  getAstatispage,
} from '@/api/elderlyRecords/routineCheck.js'
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      Shcander: [
        { name: '生活服务', value: 'COMMON' },
        { name: '餐饮休闲', value: 'CATERING' },
      ],
      list: [
        { t: '', i: '累计订单数量' },
        { t: '', i: '累计订单金额' },
        { t: '', i: '累计服务数量' },
      ],
      list_oss: [
        { t: '', i: '预警总数' },
        { t: '', i: '待处理预警' },
      ],
      message_list: [],
      tableData: [],
      page: 1,
      perPage: 6,
      total: 0,
      t_service: 0,
      ment_type: 'COMMON',
      institutionNo: '', //机构号
      customerGlobalId: '', //老人id
      institutionNo_type: '', //机构类型 1机构3政府
      percent: '',
    }
  },
  computed: {
    defaultOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  created() {
    this.institutionNo = this.$route.query.institutionNo
    this.customerGlobalId = this.$route.query.customerGlobalId
  },
  mounted() {
    this.getAreaTion()
    this.getAstatistics()
    this.getAstatispage()
  },
  methods: {
    btn_message(e) {
      console.log(e, 'ScrollEnd', '1111111')
    },
    getAstatistics() {
      getAstatistics(this.customerGlobalId).then((res) => {
        if (res.code == 0) {
          this.list_oss[0].t = res.data.total
          this.list_oss[1].t = res.data.pending
          this.percent = res.data.percent
          this.value = res.data.percent.replace('%', '')
          this.amoun()
        }
      })
    },
    getAstatispage() {
      getAstatispage(this.customerGlobalId).then((res) => {
        this.message_list = res.data
      })
    },
    getAreaTion() {
      getAreaTion(this.institutionNo).then((res) => {
        if (res.code == 0) {
          if (res.data.type == 3) {
            let obj = {
              customerGlobalId: this.customerGlobalId,
              type: 'GOV',
            }
            this.getAreasugovtome(obj)
            this.getAreasuorder()
          } else {
            let str = {
              customerGlobalId: this.customerGlobalId,
              type: 'COMMON',
            }
            this.getAreasugovtome(str)
            this.getAreacommon()
          }
          this.institutionNo_type = res.data.type
        }
      })
    },
    getAreasugovtome(data) {
      getAreasugovtome(data).then((res) => {
        if (res.code == 0) {
          this.list[0].t = res.data.totalOrderNums
          this.list[1].t = res.data.totalOrderAmount
          this.list[2].t = res.data.totalItemNums
        }
      })
    },
    getAreacommon() {
      let data = {
        customerGlobalId: this.customerGlobalId,
        page: this.page,
        perPage: this.perPage,
        type: this.ment_type,
      }
      getAreacommon(data).then((res) => {
        if (res.code == 0) {
          this.total = res.total
          this.tableData = res.data
          //     this.tableData.forEach(s => {
          //         s.itemsName=''
          //     })
          //   this.tableData.forEach((item) => {
          //       if (item.items && item.items.length > 1) {
          //       item.items.forEach((i) => {
          //           item.itemsName=item.itemsName+i.serviceName+','
          //       })
          //       } else {
          //         item.items.forEach((i) => {
          //           item.itemsName=i.serviceName
          //       })
          //     }
          //   })
        }
      })
    },
    getAreasuorder() {
      let data = {
        customerGlobalId: this.customerGlobalId,
        page: this.page,
        perPage: this.perPage,
      }
      getAreasuorder(data).then((res) => {
        if (res.code == 0) {
          this.total = res.total
          this.tableData = res.data
        }
      })
    },
    btn_service(e, i) {
      this.t_service = e
      this.ment_type = i
      let str = {
        customerGlobalId: this.customerGlobalId,
        type: i,
      }
      this.getAreasugovtome(str)
      this.getAreacommon()
    },
    handleCurrentChange(val) {
      this.page = val
      if (this.institutionNo_type == 3) {
        this.getAreasuorder()
      } else {
        this.getAreacommon()
      }
    },
    amoun() {
      var amoun = echarts.init(document.getElementById('amoun'))
      let option = {
        title: {
          text: '',
          x: 'center',
          y: 'center',
          textStyle: {
            fontWeight: 'normal',
            color: '#69B1FF',
            fontSize: '10',
          },
        },
        color: ['rgba(176, 212, 251, 1)'],
        series: [
          {
            type: 'pie',
            clockWise: true,
            radius: ['100%', '70%'],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            data: [
              {
                value: this.value / 100,
                name: '01',
                itemStyle: {
                  normal: {
                    color: {
                      // 完成的圆环的颜色
                      colorStops: [
                        {
                          offset: 0,
                          color: '#091A54', // 0% 处的颜色
                        },
                        {
                          offset: 0,
                          color: '#25D64D', // 100% 处的颜色
                        },
                      ],
                    },
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: '02',
                value: 1 - this.value / 100,
                itemStyle: {
                  normal: {
                    color: {
                      // 完成的圆环的颜色
                      colorStops: [
                        {
                          offset: 0,
                          color: '#091A54', // 0% 处的颜色
                        },
                        {
                          offset: 0,
                          color: '#091A54', // 100% 处的颜色
                        },
                      ],
                    },
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
            ],
          },
        ],
      }
      amoun.setOption(option)
      window.addEventListener('resize', function() {
        amoun.resize()
      })
    },
  },
}
</script>
<style scoped lang="scss">
.ds-basic-pp {
  background: url('~@/assets/images/zhangzhe/bj3.png') 0 0 no-repeat;
  background-size: 100% 100%;
  width: 26.33rem;
  height: 22.75rem;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 0.41rem;
  position: relative;
}

.ds-page2 {
  position: absolute;
  top: 1.66rem;
  left: 0.42rem;
  right: 0.42rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
}
.ds-cen-oss {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.ds-page-li {
  background: url('~@/assets/images/zhangzhe/bj4.png') 0 0 no-repeat;
  background-size: 100% 100%;
  width: 26.33rem;
  height: 17.58rem;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 0.41rem;
  position: relative;
}
.ds-cen-older {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 8rem;
  padding: 0.46rem 0 0.46rem 0;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  margin-top: 0.42rem;
}
.ds-can-ang1 {
  font-size: 30px;
  font-weight: bold;
  color: #b4d3fe;
}
.ds-can-ang {
  font-size: 30px;
  font-weight: bold;
  color: #ff8529;
}
.ds-can-ang2 {
  font-size: 0.5833rem;
  font-weight: 400;
  color: #8eb8e6;
}
::v-deep .el-table thead {
  color: #5176a0;
  font-weight: 500;
}
::v-deep .el-table {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  color: #8eb8e6;
}
::v-deep .el-table tr {
  z-index: 999999999;
  background: #060f35 !important;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
::v-deep .el-table__body tr:hover > td {
  background-color: rgba(255, 133, 41, 0.2) !important;
}
::v-deep .el-table::before {
  background: none !important;
}
.page {
  float: right;
  ::v-deep .el-input__inner {
    background: transparent;
    border: 1px solid #00b4ff;
    color: #4dc2ff;
  }

  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background-color: transparent;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #312316 !important;
  border: 1px solid #ed7301;
  color: #ed7301;
}
::v-deep .el-pagination.is-background .btn-next,
::v-deep .el-pagination.is-background .btn-prev,
::v-deep .el-pagination.is-background .el-pager li {
  background: transparent;
  border: 1px solid #00b4ff;
  color: #4dc2ff;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #ffffff;
}
::v-deep .el-pagination__total {
  color: #4dc2ff;
}
::v-deep .el-pagination__jump {
  color: #4dc2ff;
}
::v-deep .swiper-container {
  --swiper-navigation-size: 0;
}
::v-deep .swiper-button-next,
::v-deep .swiper-container-rtl .swiper-button-prev {
  background: none;
}
::v-deep .swiper-button-prev,
::v-deep .swiper-container-rtl .swiper-button-next {
  background: none;
}
::v-deep .swiper-button-prev {
  position: absolute;
  left: 0px;
}
::v-deep .swiper-button-next {
  position: absolute;
  right: -18px;
}
::v-deep .swiper-button-prev,
::v-deep .swiper-button-next {
  margin-top: -11px;
}

::v-deep .el-select-dropdown__list {
  background-color: #050f2d;
}
::v-deep .el-pagination__total {
  color: #8eb8e6;
}
.ds-can-ale {
  font-size: 30px;
  font-weight: bold;
  color: #ff4d4f;
}
.ds-can-ale2 {
  font-size: 30px;
  font-weight: bold;
  color: #25d64d;
}
.ds-cen-ty {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ds-wanchen-ok {
  display: flex;
  align-items: center;
  width: 8rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  margin-top: 0.42rem;
  justify-content: space-evenly;
}
.ds-wanchen-ok1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  > span:nth-of-type(1) {
    font-size: 30px;
    font-weight: bold;
    color: #25d64d;
  }
  > span:nth-of-type(2) {
    font-size: 0.5833rem;
    font-weight: 400;
    color: #8eb8e6;
  }
}
.ds-scoll-age {
  padding: 0 0.42rem;
}
.ds-scoll-age1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
}
.ds-scoll-age2 {
  display: flex;
  align-items: center;
}
.ds-apan1 {
  font-size: 0.66rem;
  font-weight: bold;
  color: #ff4d4f;
}
.ds-apan2 {
  font-size: 0.5rem;
  font-weight: 400;
  color: #ffffff;
  padding: 0.2rem;
  background: #18214a;
  border-radius: 2px;
  margin-left: 0.2rem;
}
.ds-span3 {
  font-size: 0.66rem;
  font-weight: bold;
  color: #25d64d;
}
.ds-span4 {
  font-size: 0.66rem;
  font-weight: bold;
  color: #ff8529;
}
.ds-scoll-age3 {
  display: flex;
  align-items: center;
  font-size: 0.5rem;
  font-weight: 400;
  color: #8eb8e6;
  border-bottom: 1px solid #1c3269;
  padding-bottom: 0.3rem;
}
.left-text {
  flex: 1;
  overflow: hidden;
}
.t_service1 {
  font-size: 0.583rem;
  font-weight: bold;
  color: #ff8529;
  margin-right: 0.5rem;
  position: relative;
  &::after {
    content: '';
    width: 100%;
    height: 2px;
    background: #ff8529;
    position: absolute;
    bottom: -0.2rem;
    left: 0;
  }
}
.t_service2 {
  font-size: 0.583rem;
  font-weight: bold;
  color: #8eb8e6;
  margin-right: 0.5rem;
}
.ds-cen-fover {
  display: flex;
  align-items: center;
  float: right;
  margin-top: 0.383rem;
}
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: #051038 !important;
}
.ds-cen-warning {
  display: flex;
  justify-content: space-between;
  > span:nth-of-type(1) {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
  > span:nth-of-type(2) {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #ff4d4f;
  }
}
.ds-cen-ment {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #b4d3fe;
  padding-top: 10px;
}
</style>
<style lang="scss">
.pophoveracx {
  background: #050e2c !important;
  border-radius: 2px;
  border: 1px solid #213060;
}
</style>
