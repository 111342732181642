<template>
  <!-- 尿酸 -->

  <div class="all-records">
    <!-- 查询 -->
    <time-query @changeTime="changeTime"></time-query>
    <!-- echarts图表 -->
    <!-- 尿酸 -->
    <div class="line-chart-box">
      <div class="emptyMessage" v-if="isData.length == 0">
        暂无数据
      </div>
      <LineChart
        :showTimes="showTimes"
        :isAverage="true"
        :name="'尿酸'"
        :unit="'μmol/L'"
        :average="UricAcidAverage"
        :averageDesc="
          gender == 0
            ? 'μmol/L女性正常范围：178.4~297.4μmol/L)'
            : 'μmol/L(男性正常范围：237.9~356.9μmol/L)'
        "
        :abscissaData="UricAcidAbscissaData"
        :ordinateData="UricAcidOrdinateData"
        :lineCartId="'UricAcid'"
        :max="gender == 0 ? 297.4 : 356.9"
        :min="gender == 0 ? 178.4 : 237.9"
        v-if="UricAcidAbscissaData.length != 0 && flag"
      ></LineChart>
    </div>
  </div>
</template>

<script>
// api请求
import { getArehistory, getAreaCoter } from '@/api/elderlyRecords/routineCheck.js'
import timeQuery from '../timeQuery.vue'
import LineChart from './routineCheckLineChart.vue'
export default {
  props: {
    // 传过来的身份证号
    customerGlobalId: {
      type: [String],
      default: '',
    },
  },
  components: { timeQuery, LineChart },
  // 定义数据
  data() {
    return {
      gender: '',
      // 查询表单数据
      formInline: {
        date: '',
      },
      showTimes: '',
      UricAcidAverage: '', //心率平均值
      UricAcidAbscissaData: [], //心率横坐标数据
      UricAcidOrdinateData: [], //心率纵坐标数据
      isData: [], // 是否有数据
    }
  },
  // dom加载
  mounted() {
    this.getAreaCoter()
  },
  // 定义函数
  methods: {
    getAreaCoter() {
      getAreaCoter(this.customerGlobalId).then((res) => {
        this.gender = res.data.gender
      })
    },
    changeTime(data) {
      const { date, showTimes, start, end } = data
      this.showTimes = showTimes
      this.formInline.date = date
      this.init(start, end)
    },
    async init(start, end) {
      this.flag = false
      const res = await getArehistory({
        customerGlobalId: this.customerGlobalId,
        date: this.formInline.date,
        type: 'uricAcid',
        start,
        end,
      })
      console.log(res)
      if (res.code == 0) {
        //处理数据
        this.isData = res.data.collectionTime
        // 尿酸
        this.UricAcidAbscissaData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.uricAcid)
        ).abscissaData
        this.UricAcidOrdinateData = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.uricAcid)
        ).ordinateData
        this.UricAcidAverage = this.processingData(
          _.cloneDeep(res.data.collectionTime),
          _.cloneDeep(res.data.uricAcid)
        ).mean
        this.flag = true
      }
    },
    processingData(abscissaData, ordinateData) {
      ordinateData.forEach((item, index) => {
        if (item == null) {
          abscissaData[index] = null
        }
      })
      ordinateData = ordinateData.filter(function(i) {
        return i
      })
      abscissaData = abscissaData.filter(function(i) {
        return i
      })
      let mean
      if (ordinateData !== null && ordinateData.length != 0) {
        mean = _.mean(ordinateData)
      }
      return {
        abscissaData: abscissaData,
        ordinateData: ordinateData,
        mean: mean,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.all-records {
  position: relative;
}
.emptyMessage {
  position: absolute;
  font-size: 1.2rem;
  color: #2d3769;
  top: 9rem;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
