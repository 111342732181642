<template>
  <div class="time-query">
    <el-form
      size="small"
      :inline="true"
      :model="formInline"
      class="demo-form-inline"
    >
      <el-radio-group v-model="formInline.tabPosition" @input="changeTime">
        <el-radio-button label="day">日</el-radio-button>
        <el-radio-button label="week">周</el-radio-button>
        <el-radio-button label="month">月</el-radio-button>
        <!-- <el-radio-button label="halfYear">半年</el-radio-button> -->
        <el-radio-button label="year">年</el-radio-button>
      </el-radio-group>
    </el-form>
    <!-- value-format="yyyy-MM-dd" -->
    <div  style=" margin-top: 0.8rem;margin-left:1rem">
      <div v-if="formInline.tabPosition == 'day'" class="choicesTimes">
        请选择日期
      </div>
      <div v-if="formInline.tabPosition == 'week'" class="choicesTimes">
        请选择周
      </div>
      <div v-if="formInline.tabPosition == 'month'" class="choicesTimes">
        请选择月份
      </div>
      <div v-if="formInline.tabPosition == 'year'" class="choicesTimes">
        请选择年份
      </div>
      <el-date-picker
        v-model="times"
        type="date"
        placeholder="选择日期"
        v-show="formInline.tabPosition == 'day'"
        @change="inputChangeTimes"
        :picker-options="pickerOptions1"
        size="small"
        :clearable="false"
        popper-class="ds-cen-packon"
      >
      </el-date-picker>
      <el-date-picker
        v-model="times"
        type="week"
        format="yyyy 第 WW 周"
        placeholder="选择周"
        @change="inputChangeTimes"
        v-show="formInline.tabPosition == 'week'"
        :picker-options="pickerOptions1"
        size="small"
        :clearable="false"
        popper-class="ds-cen-packon"
      >
      </el-date-picker>
      <el-date-picker
        v-model="times"
        type="month"
        placeholder="选择月"
        v-show="formInline.tabPosition == 'month'"
        @change="inputChangeTimes"
        :picker-options="pickerOptions1"
        size="small"
        :clearable="false"
        popper-class="ds-cen-packon"
      >
      </el-date-picker>
      <el-date-picker
        v-model="times"
        type="year"
        placeholder="选择年"
        v-show="formInline.tabPosition == 'year'"
        @change="inputChangeTimes"
        :picker-options="pickerOptions1"
        size="small"
        :clearable="false"
        popper-class="ds-cen-packon"
      >
      </el-date-picker>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
moment.locale('zh-cn', {
  week: {
    dow: 1,
  },
})
export default {
  created() {
    this.changeTime()
  },
  methods: {
    changeTime() {
      this.times = moment(new Date())
      switch (this.formInline.tabPosition) {
        case 'day':
          this.dayChange()
          break
        case 'week':
          this.weekChange()
          break
        case 'month':
          this.monthChange()
          break
        case 'year':
          this.yearChange()
          break
      }
    },
    dayChange() {
      this.showTimes =
        moment(this.times).format('YYYY年MM月DD日') + '  ' + this.getWeek()
      this.start = moment(this.times)
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
      this.end = moment(this.times)
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
      this.$emit('changeTime', {
        date: this.formInline.tabPosition,
        showTimes: this.showTimes,
        start: this.start,
        end: this.end,
      })
    },
    getWeek() {
      let week = moment(this.times).day()
      switch (week) {
        case 1:
          return '星期一'
        case 2:
          return '星期二'
        case 3:
          return '星期三'
        case 4:
          return '星期四'
        case 5:
          return '星期五'
        case 6:
          return '星期六'
        case 0:
          return '星期天'
      }
    },
    //周
    weekChange() {
      let startYear = moment(this.times)
        .startOf('week')
        .year()
      let startMonth = moment(this.times)
        .startOf('week')
        .month()
      let endYear
      let endMonth
      let endTimes
      this.start = moment(this.times)
        .startOf('week')
        .format('YYYY-MM-DD HH:mm:ss')
      const weekEndTime = moment(this.times).endOf('week')
      if (weekEndTime > moment(new Date())) {
        endYear = moment(new Date()).year()
        endMonth = moment(new Date()).month()
        endTimes = moment(new Date()).format('YYYY年MM月DD日')
        this.end = moment().format('YYYY-MM-DD HH:mm:ss')
        endYear = moment(new Date()).year()
      } else {
        endTimes = moment(this.times)
          .endOf('week')
          .format('YYYY年MM月DD日')
        this.end = moment(this.times)
          .endOf('week')
          .format('YYYY-MM-DD HH:mm:ss')
        endYear = moment(this.times)
          .endOf('week')
          .year()
      }
      if (startYear == endYear) {
        if (startMonth == endMonth) {
          if (weekEndTime > moment(new Date())) {
            this.showTimes =
              moment(this.times)
                .startOf('week')
                .format('YYYY年MM月DD日') +
              '至' +
              moment(new Date()).format('DD日')
          } else {
            this.showTimes =
              moment(this.times)
                .startOf('week')
                .format('YYYY年MM月DD日') +
              '至' +
              moment(this.times)
                .endOf('week')
                .format('DD日')
          }
        } else {
          if (weekEndTime > moment(new Date())) {
            this.showTimes =
              moment(this.times)
                .startOf('week')
                .format('YYYY年MM月DD日') +
              '至' +
              moment(new Date()).format('MM月DD日')
          } else {
            this.showTimes =
              moment(this.times)
                .startOf('week')
                .format('YYYY年MM月DD日') +
              '至' +
              moment(this.times)
                .endOf('week')
                .format('MM月DD日')
          }
        }
      } else {
        this.showTimes =
          moment(this.times)
            .startOf('week')
            .format('YYYY年MM月DD日') +
          '至' +
          endTimes
      }
      this.$emit('changeTime', {
        date: this.formInline.tabPosition,
        showTimes: this.showTimes,
        start: this.start,
        end: this.end,
      })
    },
    //月
    monthChange() {
      this.showTimes = moment(this.times).format('YYYY年MM月')
      this.start = moment(this.times)
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss')
      const monthEndTime = moment(this.times).endOf('month')
      if (monthEndTime > moment()) {
        this.end = moment().format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.end = moment(this.times)
          .endOf('month')
          .format('YYYY-MM-DD HH:mm:ss')
      }
      this.$emit('changeTime', {
        date: this.formInline.tabPosition,
        showTimes: this.showTimes,
        start: this.start,
        end: this.end,
      })
    },
    //年
    yearChange() {
      this.start = moment(this.times)
        .startOf('year')
        .format('YYYY-MM-DD HH:mm:ss')
      const yearEndTime = moment(this.times).endOf('year')
      if (yearEndTime > moment(new Date())) {
        this.showTimes =
          moment(this.times)
            .startOf('year')
            .format('YYYY年MM月') +
          '至' +
          moment(new Date()).format('YYYY年MM月')
        this.end = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.showTimes =
          moment(this.times)
            .startOf('year')
            .format('YYYY年MM月') +
          '至' +
          moment(this.times)
            .endOf('year')
            .format('YYYY年MM月')
        this.end = moment(this.times)
          .endOf('year')
          .format('YYYY-MM-DD HH:mm:ss')
      }
      this.$emit('changeTime', {
        date: this.formInline.tabPosition,
        showTimes: this.showTimes,
        start: this.start,
        end: this.end,
      })
    },
    inputChangeTimes() {
      switch (this.formInline.tabPosition) {
        case 'day':
          this.dayChange()
          break
        case 'week':
          this.weekChange()
          break
        case 'month':
          this.monthChange()
          break
        case 'year':
          this.yearChange()
          break
      }
    },
  },
  data() {
    return {
      formInline: { tabPosition: 'year' },
      showTimes: '',
      times: '',
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        firstDayOfWeek: 1,
      },
      start: '',
      end: '',
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-radio-button__inner {
  width: 54px;
  height: 32px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-left: 1px solid #26305a !important;
  background: #18214a !important;
  border: 1px solid #26305a;
  margin-top: 0.8rem;
}
::v-deep .el-input__inner {
  -webkit-appearance: none;
  /* background-color: #FFF; */
  background-image: none;
  border-radius: 4px;
  /* border: 1px solid #DCDFE6; */
  box-sizing: border-box;
  color: #8eb8e6;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 40px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  background: #18214a;
  border: 1px solid #232d57;
}
::v-deep .is-active {
  .el-radio-button__inner {
    background-color: #36447d !important;
    color: #8eb8e6;
    border: 1px solid #213060;
    border-left: none;
    -webkit-box-shadow: -1px 0 0 0 #26305a;
  }
}

::v-deep .el-radio-button__inner:hover {
  color: #595959;
}

.choicesTimes {
  float: left;
  font-size: 0.5833rem;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 32px;
  margin-right: 12px;
}
.time-query {
  display: flex;
  align-items: center;
}
</style>
<style lang="scss">
.ds-cen-packon{
    background: rgba(4,9,41,0.95);
box-shadow: inset 0px 0px 24px 0px rgba(0,122,255,0.81);
border-radius: 2px;
border: 1px solid #0C87FF;
.el-popper .popper__arrow, .el-popper .popper__arrow::after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: none;
    border-style: none !important;
}
.el-date-picker__header-label{
    color: #8EB8E6;
}
.el-date-picker__header--bordered {
    margin-bottom: 0;
    padding-bottom: 12px;
    border-bottom: solid 1px #1C3269;
}
.el-year-table td.disabled .cell {
    background: #1A2863;
    cursor: not-allowed;
    color: #5E7DB0;
}
.el-year-table td .cell {
    width: 48px;
    height: 32px;
    display: block;
    /* line-height: 32px; */
    color: #8EB8E6;
    margin: 0 auto;
}
.el-month-table td.disabled .cell {
    background: #1A2863;
    cursor: not-allowed;
    color: #5E7DB0;
}
.el-date-table th {
    padding: 5px;
    color: #606266;
    font-weight: 400;
    border-bottom: solid 1px #1C3269;
}
.el-date-table td.disabled div {
    background: #1A2863;
    opacity: 1;
    cursor: not-allowed;
    color: #5E7DB0;
}
.el-date-table td.in-range div, .el-date-table td.in-range div:hover, .el-date-table.is-week-mode .el-date-table__row.current div, .el-date-table.is-week-mode .el-date-table__row:hover div {
    background-color: #1A2863;
}
.el-icon-d-arrow-right:before {
    content: "\e6dc";
    color: #8EB8E6;
}
.el-icon-d-arrow-left:before {
    content: "\e6dd";
    color: #8EB8E6;
}
.el-popper[x-placement^=bottom] .popper__arrow {
    top: -6px;
    left: 50%;
    margin-right: 3px;
    border-top-width: 0;
    /* border-bottom-color: #EBEEF5; */
}
.el-popper .popper__arrow::after {
  content: '';
  border-width: 0px;
}
.el-popper .popper__arrow {
  border-width: 0px;
}
}
</style>
